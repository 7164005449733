import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";

const Profiles = ({match}) => {
    return (
        <Switch>
            <Route path={`/:username`} component={asyncComponent(() => import('./Profile'))}/>
        </Switch>
    )
};

export default Profiles;
