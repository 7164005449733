import React, {Fragment, useEffect} from "react";
import {Button, Card, Col, Collapse, message, Popconfirm, Row, Table} from "antd";
import {makeStyles} from '@material-ui/core/styles';
import talentApiService from "../../../util/services/TalentsApiService";
import Widget from "../../../components/Widget";
import siteConfig from "../../../config/site.config";
import IntlMessages from "../../../util/IntlMessages";
import {Link} from "react-router-dom";
import compainApiService from "../../../util/services/CompainApiService";
import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import advertiserApiService from "../../../util/services/AdvertisersApiService";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import Loader from "../loader";
import {abbrNum, pourcentNum} from "../../../util/helpers";
import AmCharts from "@amcharts/amcharts3-react";

const Panel = Collapse.Panel;


const DetailCompain = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            margin: '0% 0% 50% 50%',
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    }));
    const [Compain, setCompain] = React.useState([]);
    const [tableCompainData, setTableCompainData] = React.useState([]);
    const [CompainTalents, setCompainTalents] = React.useState([]);
    const [compain_id, setCompain_id] = React.useState(props.match.params.compain_id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [stats, setStats] = React.useState([]);
    const [statsEnabled, setStatsEnabled] = React.useState([]);
    const [activeIndex, setactiveIndex] = React.useState(0);

    const [state, setState] = React.useState({
        right: false,
    });
    const configGender = {
        "type": "pie",
        "theme": "light",
        "dataProvider":
            [
                {
                    "country": "Homme",
                    "value": 501.9
                }, {
                "country": "Femme",
                "value": 301.9
            },],
        "titleField": "country",
        "valueField": "value",
        "labelRadius": 5,

        "radius": "35%",
        "innerRadius": "60%",
        "labelText": "[[title]]",
        "export": {
            "enabled": false
        }
    };

    const configCountry = {
        "type": "pie",
        "theme": "light",
        "dataProvider":
            [
                {
                    "country": "Lithuania",
                    "value": 260
                }, {
                "country": "Ireland",
                "value": 201
            }, {
                "country": "Germany",
                "value": 65
            }, {
                "country": "Australia",
                "value": 39
            }, {
                "country": "UK",
                "value": 19
            }, {
                "country": "Latvia",
                "value": 10
            }],
        "titleField": "country",
        "valueField": "value",
        "labelRadius": 5,

        "radius": "35%",
        "innerRadius": "60%",
        "labelText": "[[title]]",
        "export": {
            "enabled": false
        }
    };

    const configAge = {
        "type": "pie",
        "theme": "light",
        "dataProvider": [{
            "title": "20-25",
            "value": 4852
        }, {
            "title": "25-30",
            "value": 9899
        },
            {
                "title": "30-40",
                "value": 4852
            },
            {
                "title": "40-50",
                "value": 7845
            },
            {
                "title": "+50",
                "value": 458
            }],
        "titleField": "title",
        "valueField": "value",
        "labelRadius": 5,
        "radius": "35%",
        "innerRadius": "60%",
        "labelText": "[[title]]",
        "export": {
            "enabled": false
        }
    };

    const configAppareil = {
        "type": "pie",
        "theme": "light",
        "innerRadius": "60%",
        "dataProvider": [{
            "country": "Iphone",
            "value": 501.9
        }, {
            "country": "Samsung",
            "value": 301.9
        }, {
            "country": "Oppo",
            "value": 201.1
        }, {
            "country": "Huawei",
            "value": 500
        }],
        "titleField": "country",
        "valueField": "value",
        "labelRadius": 5,

        "radius": "35%",
        "labelText": "[[title]]",
        "export": {
            "enabled": false
        }
    };

    useEffect(() => {
        compainApiService
            .getCompainInfo(compain_id)
            .then(res => {
                console.log(res.data.data)
                setIsLoading(false);
                let compainData = res.data.data;
                compainData.key = compainData.compain_id;
                setCompain(compainData || null);
                setTableCompainData([compainData]);
            });
    }, [compain_id]);

    useEffect(() => {
        talentApiService
            .getCompainDetails(compain_id)
            .then(res => {
                setIsLoading(false);
                let talentsData = [];
                res.data.data.forEach(t => {
                    t.key = t.talent_id;
                    talentsData.push(t);
                })
                setCompainTalents(talentsData);
            });
    }, [compain_id]);

    useEffect(() => {
        advertiserApiService
            .getStatCompain(compain_id)
            .then(res => {
                setStats(res.data.data || []);
            });

    }, [isLoading]);

    const AcceptInvitation = (request) => {
        let formData = {
            compain_id: request.compain_id,
            talent_id: request.influencer_id
        }
        talentApiService
            .acceptInvitation(formData).then(res => {
            if (res.data.success === true) {
                message.success('Invitation Accepted With success');
                window.location.reload(true);

            }
        })
    };

    function cancel(e) {
    }

    const InfoCompainColumns = [
        {
            title: 'Marque',
            dataIndex: 'marque',
            key: 'marque',
            render: (text, record) => {
                return <div className="gx-flex-row gx-align-items-center">
                    {/*<img className="gx-rounded-circle gx-size-30 gx-mr-2" src={siteConfig.AppConfig.baseStyleImageUrl + text} alt=""/>*/}
                    <p className="gx-mb-0">{record.marque}</p>
                </div>
            },
        },
        {
            title: 'Campaigne',
            dataIndex: 'compain_name',
            key: 'compain_name',
            render: (text, record) => {
                return <span className="gx-text-grey">{record.compain_name}</span>
            },
        },
        {
            title: 'Date de Début',
            dataIndex: 'start_date_at',
            key: 'start_date_at',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{record?.start_date_at}</span>
            },
        },
        {
            title: 'Date de fin ',
            dataIndex: 'end_date_at',
            key: 'end_date_at',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{record?.end_date_at}</span>
            },
        },
        {
            title: 'Total Visits ',
            dataIndex: 'total_visits',
            key: 'total_visits',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{record?.total_visits}</span>
            },
        },
        {
            title: 'Total Visits Price',
            dataIndex: 'total_visits_price',
            dataIndexkey: 'total_visits_price',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{record?.total_visits_price} dt</span>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
          <button className="ant-btn ant-btn-success">{record?.status}</button></span>
            },
        },
    ];

    const InfoTalentsColumns = [
        {
            title: 'Talent ',
            dataIndex: 'influencer_efile_id',
            render: (text, record) => {
                return <div className="gx-flex-row gx-align-items-center">
                    <img className="gx-rounded-circle gx-size-30 gx-mr-2"
                         src={siteConfig.AppConfig.baseStyleImageUrl + text} alt=""/>
                    <p className="gx-mb-0"><a href={siteConfig.TalentUrl + '/' + record?.influencer?.username}
                                              target="_blank">{record?.influencer?.influencer_name}</a></p>
                </div>
            },
        },
        {
            title: 'Engagement rate',
            dataIndex: 'engagement_rate',
            render: (text, record) => {
                return <span
                    className="gx-text-grey">{pourcentNum(record?.influencer?.ig_data?.engagement_rate, 2) + '%'}</span>
            },

        },
        {
            title: 'Followers',
            dataIndex: 'Followers',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{abbrNum(record?.influencer?.ig_data?.followers_count, 2)}</span>
            },
        },
        {
            title: 'Total Visits ',
            dataIndex: 'count_total_visits',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{record?.count_total_visits}</span>
            },
        },
        {
            title: 'Total Visits Price',
            dataIndex: 'total_visits_price',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
        <i className="gx-text-grey"/>{record.total_visits_price ? record?.total_visits_price : 0} dt</span>
            },
        },
        {
            title: 'Statut',
            dataIndex: 'status',
            render: (text, record) => {
                return <span className="gx-text-primary gx-pointer">
          {record.compain.category === 'public' && record.status === 'need_validation' ?
              <Popconfirm title="Are you sure you want to accepte this request?"
                          onConfirm={() => AcceptInvitation(record)} onCancel={cancel} okText="Yes"
                          cancelText="No">
                  <span className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-mr-4 gx-text-uppercas">En attente</span>
              </Popconfirm>
              :
              <button className="ant-btn ant-btn-success">{record.status}</button>
          }
            </span>
            },
        },
        {
            title: 'Stats',
            dataIndex: 'stats',
            key: 'stats',
            render: (text, record) => {
                return <i className="icon icon-chart" onClick={toggleDrawer('right', true, record)}
                          style={{cursor: 'pointer'}}/>

            },
        },


    ];

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
        if (data) {
            setStatsEnabled(data.stats)
        }
    };
    const classes = useStyles();

    const list = (anchor) => (<div className={clsx(classes.list, {
            [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })} role="presentation">
            <Card className="gx-card" title="visit evolution">
                {/*<ResponsiveContainer width="100%" height={200}>*/}
                {/*  <AreaChart data={statsEnabled}*/}
                {/*             margin={{top: 10, right: 0, left: -15, bottom: 0}}>*/}
                {/*    <XAxis dataKey="date"/>*/}
                {/*    <YAxis/>*/}
                {/*    <CartesianGrid strokeDasharray="3 3"/>*/}
                {/*    <Tooltip/>*/}
                {/*    <Area type='monotone' dataKey='total_visit' fillOpacity={100} stroke='#003366' fill='#003366'/>*/}
                {/*  </AreaChart>*/}
                {/*</ResponsiveContainer>*/}
                <ResponsiveContainer width="100%" height={180}>
                    <AreaChart data={statsEnabled}
                               margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                        <XAxis dataKey="date"/>
                        <YAxis/>
                        <Tooltip/>
                        <defs>
                            <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="5%" stopColor="#4ECDE4" stopOpacity={0.9}/>
                                <stop offset="95%" stopColor="#06BB8A" stopOpacity={0.9}/>
                            </linearGradient>
                        </defs>
                        <Area dataKey="total_visit" strokeWidth={0} stackId="2" stroke='#4D95F3' fill="url(#color4)"
                              fillOpacity={1}/>
                    </AreaChart>
                </ResponsiveContainer>
            </Card>
            <Card>
                <Row>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Card className="gx-card gx-ml-2 header-stats" title="Sessions par age">
                            <AmCharts.React style={{width: "100%", height: "500px"}} options={configAge}/>
                        </Card>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Card className="gx-card" title="Sessions par pays">
                            <AmCharts.React style={{width: "100%", height: "500px"}}
                                            options={configCountry}/>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Card className="gx-card" title="Sessions par appareil">
                            <AmCharts.React style={{width: "100%", height: "500px"}} options={configAppareil}/>
                        </Card>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Card className="gx-card" title="Sessions par gender">
                            <AmCharts.React style={{width: "100%", height: "500px"}} options={configGender}/>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </div>
    )
    return (
        <div>
            {!isLoading ?
                <Fragment>
                    <Button type="primary"><IntlMessages id="compain.update"/><Link
                        to={siteConfig.prefixMe + "/update-compain/" + compain_id}/></Button>

                    {tableCompainData && tableCompainData.length ?
                        <Widget
                            title={
                                <h2 className="h4 gx-text-capitalize gx-mb-0">
                                    Campaign Details</h2>
                            }>
                            <div className="gx-table-responsive">
                                <Table className="gx-table-no-bordered" columns={InfoCompainColumns}
                                       dataSource={tableCompainData} rowKey={"compain_id"} pagination={false}
                                       size="small"/>
                            </div>
                            <Card className="gx-card gx-card-p-title" title="Info">
                                <Row>
                                    <Col span={24}>
                                        <Collapse>
                                            <Panel header="Description" key="1" showArrow={false}>
                                                <p>{tableCompainData[0].description}</p>
                                            </Panel>
                                            {tableCompainData[0].category === 'public' ?
                                                <Panel header="Offre" key="2" showArrow={false}>
                                                    <p>{tableCompainData[0].offre}</p>
                                                </Panel> : ''
                                            }
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Card>
                        </Widget>
                        : ''}

                    {CompainTalents && CompainTalents.length ?
                        <Widget
                            title={
                                <h2 className="h4 gx-text-capitalize gx-mb-0">
                                    List Talents</h2>
                            }>
                            <div className="gx-table-responsive">
                                <Table className="gx-table-no-bordered" columns={InfoTalentsColumns}
                                       dataSource={CompainTalents} pagination={false}
                                       size="small"/>
                            </div>
                            <Drawer anchor={'right'} open={state['right']}
                                    onClose={toggleDrawer('right', false)}>
                                {list('right')}
                            </Drawer>
                        </Widget>
                        : ''}
                    <Widget title="Visit Evolution">
                        {/*<ResponsiveContainer width="100%" height={200}>*/}
                        {/*  <AreaChart data={stats}*/}
                        {/*             margin={{top: 10, right: 0, left: -15, bottom: 0}}>*/}
                        {/*    <XAxis dataKey="date"/>*/}
                        {/*    <YAxis/>*/}
                        {/*    <CartesianGrid strokeDasharray="3 3"/>*/}
                        {/*    <Tooltip/>*/}
                        {/*    <Area type='monotone' dataKey='total_visit' fillOpacity={100} stroke='#003366' fill='#003366'/>*/}
                        {/*  </AreaChart>*/}
                        {/*</ResponsiveContainer>*/}
                        <ResponsiveContainer width="100%" height={180}>
                            <AreaChart data={stats}
                                       margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                <XAxis dataKey="date"/>
                                <YAxis/>
                                <Tooltip/>
                                <defs>
                                    <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
                                        <stop offset="5%" stopColor="#4ECDE4" stopOpacity={0.9}/>
                                        <stop offset="95%" stopColor="#06BB8A" stopOpacity={0.9}/>
                                    </linearGradient>
                                </defs>
                                <Area dataKey="total_visit" strokeWidth={0} stackId="2" stroke='#4D95F3'
                                      fill="url(#color4)"
                                      fillOpacity={1}/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </Widget>
                    <Card>
                        <Row>
                            <Col lg={12} md={12} sm={24} xs={24}>
                                <Card className="gx-card gx-ml-2 header-stats" title="Sessions par age">
                                    <AmCharts.React style={{width: "100%", height: "500px"}} options={configAge}/>
                                </Card>
                            </Col>
                            <Col lg={12} md={12} sm={24} xs={24}>
                                <Card className="gx-card" title="Sessions par pays">
                                    <AmCharts.React style={{width: "100%", height: "500px"}}
                                                    options={configCountry}/>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={24} xs={24}>
                                <Card className="gx-card" title="Sessions par appareil">
                                    <AmCharts.React style={{width: "100%", height: "500px"}} options={configAppareil}/>
                                </Card>
                            </Col>
                            <Col lg={12} md={12} sm={24} xs={24}>
                                <Card className="gx-card" title="Sessions par gender">
                                    <AmCharts.React style={{width: "100%", height: "500px"}} options={configGender}/>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Fragment>
                : <Loader/>}
        </div>
    );
};

export default DetailCompain;
