import '../../assets/scss/App.scss'
import React from "react";

const Loader = () => {
    return (
        <div id="preloader-wrapper" style={{borderColor: '#ffffff'}}>
            <div className="preloader-container">
                <div className="cssload-loader">
                    <div className="cssload-inner cssload-one" style={{borderColor: '#fd67af'}}/>
                    <div className="cssload-inner cssload-two" style={{borderColor: '#9201fe'}}/>
                    <div className="cssload-inner cssload-three" style={{borderColor: '#49cfff'}}/>
                </div>
            </div>
        </div>
    )
}
export default Loader;
