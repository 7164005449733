import React, {useEffect} from "react";
import {Button, Card, Form, message, Upload} from "antd";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import siteConfig from "../../../config/site.config";
import {post} from "axios";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IntlMessages from "../../../util/IntlMessages";
import advertiserApiService from "../../../util/services/AdvertisersApiService";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const UpdatePicturesProfile = ({picturesList, enable_update, disableDrawerManagingPictures}) => {
    const [form] = Form.useForm();
    const [pictures, setPictures] = React.useState(picturesList || []);
    const [picturesProps, setPicturesProps] = React.useState({
        listType: 'picture',
        defaultFileList: [...pictures],
    });
    const [stateDrawer, setStateDrawer] = React.useState(enable_update);
    const useStyles = makeStyles({
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    });
    const classes = useStyles();
    const onCancel = () => {

    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        disableDrawerManagingPictures(false);
        setStateDrawer(open);
    };

    const onFinish = (formData) => {
       let FormData = {};
        let pictures = []
        if (picturesProps && picturesProps.defaultFileList && picturesProps.defaultFileList.length !== 0) {
            picturesProps.defaultFileList.forEach((picture, idx) => {
                let findIdx = pictures.findIndex(item => item === picture.id)
                if (findIdx === -1) {
                    pictures.push(picture.id)
                }
            })
        }
        FormData.pictures = pictures
        advertiserApiService.updateMyPictures(FormData.pictures).then(res => {
            disableDrawerManagingPictures(false)
            message.success('Pictures Updated With success');
        }).catch(err => {
            const res = err.response;
            if (typeof res === 'undefined') {
                return;
            }
            message.error('An error occurred please try again later');
        })
    };

    const handleUploadClick = event => {
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            return post(siteConfig.AppConfig.fileUploadUrl, formData, {
                headers: {
                    'Content-Type': `multipart/form-data;`,
                },
                timeout: 30000,
            }).then(res => {
                let item_picture = {
                    id: res.data.data,
                    uid: pictures.length,
                    url: siteConfig.AppConfig.baseStyleImageUrl + res.data.data
                };
                pictures.push(item_picture);
                const findIdx = picturesProps.defaultFileList.findIndex(item => item.id === res.data.data);
                if (findIdx === -1) {
                    if (picturesProps && picturesProps.defaultFileList) {
                        picturesProps.defaultFileList.push(item_picture)
                    }
                }
                setPictures(pictures);
                const propsPictures = {
                    listType: 'picture',
                    defaultFileList: [...pictures],
                };
                setPicturesProps(propsPictures);
            })
        }
    };

    const FormPictures = (anchor) => {
        return (<div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            <Card className="gx-card" title="Managing Pictures">
                <Form
                    {...formItemLayout}
                    form={form}
                    name="update"
                    onFinish={onFinish}
                >
                    <Card className="gx-card" >
                        <div className={"group-file-upload"}>
                            <input
                                accept="image/*"
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleUploadClick}

                            />
                            <Button icon={<UploadOutlined/>} onClick={() => {
                                document.getElementById('contained-button-file').click()
                            }}>Click to upload</Button>
                        </div>
                        <Upload {...picturesProps} onChange={value => picturesProps.defaultFileList = value.fileList}/>
                        <br/>
                    </Card>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                        <Button type="secondary" onClick={() => disableDrawerManagingPictures(false)}><IntlMessages
                            id="drawer.cancel"/></Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>)
    };

    return (
        <Drawer anchor={'right'} open={stateDrawer}
                onClose={toggleDrawer(false)}>
            {FormPictures('right')}
        </Drawer>
    )
}
export default UpdatePicturesProfile
