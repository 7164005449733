import React, {useEffect} from "react";
import {Avatar, Col, Input, message, Popconfirm, Popover, Row} from "antd";
import siteConfig from "../../../config/site.config";
import {useIntl} from "react-intl";
import Pagination from "@material-ui/lab/Pagination";
import Widget from "../../../components/Widget";
import advertiserApiService from "../../../util/services/AdvertisersApiService";
import AddAd from "./add-ad/add-ad";
import UpdateProfileAdvertiser from "./add-ad/update-ad/update-profile-ad";
import Loader from "../../compain-manager/loader";

const Search = Input.Search;

const AdvertisersManager = (props) => {
    const [allAdvertisers, setAllAdvertisers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0)
    const [offset, setOffset] = React.useState(0)
    const [showDrawerAdd, setShowDrawerAdd] = React.useState(false);
    const [showDrawerUpdate, setShowDrawerUpdate] = React.useState(false);
    const [advertiser_id, setAdvertiser_id] = React.useState(false);
    const [meta_key, setMeta_key] = React.useState('')

    const intl = useIntl();

    useEffect(() => {
        getAllAdvertisers(page)
    }, [true]);

    const getAllAdvertisers = (page) => {
        let filter = [];
        filter.push({
            operator: 'and',
            conditions: [
                {
                    field: 'category',
                    operator: 'eq',
                    value: 'AD'
                }
            ]
        });
        advertiserApiService
            .find({
                limit: 8,
                page: page,
                sortBy: 'created_at',
                sortDir: 'DESC',
                filter: filter
            })
            .then(res => {
                setIsLoading(false);
                setAllAdvertisers(res.data.data || []);
                setPages(res.data?.attributes?.pages || null);
                setOffset(res.data?.attributes?.offset || null);
                setCount(res.data?.attributes?.count || null)
            });
    };

    const onFilterAdByCode = (code) => {
        setMeta_key(code)
        if (code && code !== '' && code.toString().length > 3) {
            advertiserApiService
                .find({
                    limit: -1,
                    meta_key: code.toString(),
                    sortBy: 'created_at',
                    sortDir: 'DESC',
                })
                .then(res => {
                    setIsLoading(false);
                    setAllAdvertisers(res.data.data || []);
                    setPages(res.data?.attributes?.pages || null);
                    setOffset(res.data?.attributes?.offset || null);
                    setCount(res.data?.attributes?.count || null)
                });
        } else {
            getAllAdvertisers(1)
        }
    };

    const onChangePage = (event, newPage) => {
        let current_page = newPage
        setPage(current_page)
        getAllAdvertisers(current_page)
    };
    const EnableDrawerAddProfile = () => {
        setShowDrawerAdd(true)
    }
    const disableDrawerAdd = (event) => {
        setShowDrawerAdd(event);
        getAllAdvertisers(1)
    };

    const disableDrawerUpdate = (event) => {
        setShowDrawerUpdate(event);
        getAllAdvertisers(1)
    };

    const EnableDrawerUpdateAd = (advertiser_id) => {
        setAdvertiser_id(advertiser_id)
        setShowDrawerUpdate(true)
    };

    const userMenuOptions = (advertiser_id) => (
        <ul className="gx-user-popover">
            <li onClick={() => EnableDrawerUpdateAd(advertiser_id)}>Update profile</li>
            <Popconfirm title="Are you sure delete this advertiser?" onConfirm={() => confirm(advertiser_id)}
                        onCancel={cancel} okText="Yes"
                        cancelText="No">
                <li>Delete</li>
            </Popconfirm>
        </ul>
    );

    function cancel(e) {
    };

    function confirm(advertiser_id) {
        advertiserApiService.delete({id: advertiser_id}).then(res => {
            message.success('Advertiser Deleted With success');
            getAllAdvertisers(1)
        })
    }

    return (
        <div>
            {!isLoading ?
                <>
                    <div className={"gx-mb-3"} style={{textAlign: "right"}}>
                        <button className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-text-uppercase "
                                onClick={EnableDrawerAddProfile}>
                            Add
                        </button>
                    </div>
                    <Search
                        enterButton="Search" size="large"
                        placeholder="input advertiser name"
                        onSearch={value => onFilterAdByCode(value)}
                        style={{width: 500}}
                    />
                    <Row>
                        {allAdvertisers.map((item_advertiser, idx) => (
                            <Col key={idx} xl={6} md={8} sm={12} xs={24}>
                                <Widget styleName="gx-card-full" extra={
                                    <Popover content={userMenuOptions(item_advertiser.advertiser_id)}>
                                        <i className="icon icon-setting gx-text-grey gx-fs-xl"/>
                                    </Popover>}>
                                    <div className="gx-text-center gx-px-3 gx-pt-3">
                                        <div
                                            className="gx-d-flex gx-justify-content-around gx-align-items-center gx-mb-3">
                                            <Avatar className="gx-size-80"
                                                    src={siteConfig.AppConfig.baseStyleImageUrl + item_advertiser.profile_efile_id}/>
                                        </div>
                                        <div className="gx-mb-3">
                                            <h2>{item_advertiser?.advertiser_name}</h2>
                                            <p className="gx-text-grey"><a target={"_blank"}
                                                                           href={siteConfig.SignUpAdUrl + '?code=' + item_advertiser?.code}>{item_advertiser?.code}</a>
                                            </p>
                                            <p className="gx-text-grey"><a target={"_blank"}
                                                                           href={"https://instagram.com/" + item_advertiser?.ig_username}>@{item_advertiser?.ig_username}</a>
                                            </p>
                                        </div>
                                    </div>
                                </Widget>
                            </Col>

                        ))}
                    </Row>
                    {showDrawerAdd ?
                        <AddAd enable_add={showDrawerAdd} disableDrawerAdd={disableDrawerAdd} category='AD' profile_id='3' title="Add advertiser"/> : ''
                    }
                    {showDrawerUpdate ?
                        <UpdateProfileAdvertiser enable_update={showDrawerUpdate}
                                                 disableDrawerUpdate={disableDrawerUpdate}
                                                 advertiser_id={advertiser_id}/> : ''
                    }
                    <Pagination style={{float: "right"}} count={pages} color="primary"
                                onChange={onChangePage}/>
                </> :
                <Loader/>
            }

        </div>
    );
};

export default AdvertisersManager;


