import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input, message, Radio} from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import investigationApiService from "../../util/services/InvestigationApiService";

const RadioGroup = Radio.Group;

export const AdvertiserInvestigation = (props) => {

    const [showInvestigation, setShowInvestigation] = React.useState(false);
    const [investigation_type, setInvestigationType] = React.useState(props.investigation_type);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [collaborations, setCollaborations] = React.useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!!!Number(localStorage.getItem("hide_modal_investigation_" + investigation_type))) {
            investigationApiService.getByUserId(localStorage.getItem('user_id')).then(res => {
                setShowInvestigation(!res.data.exist || false);
                if (!!res.data.exist) {
                    onCancel();
                }
            });
        }
    }, [props]);

    const onConfirm = () => {
        setShowInvestigation(false);
    };

    const onCancel = () => {
        localStorage.setItem("hide_modal_investigation_" + investigation_type, "1");
        setShowInvestigation(false);
    };

    const onFinish = (formData) => {
        let values = {...formData, ...{}};
        values.collaboration_types = collaborations;
        values.user_id = localStorage.getItem('user_id');
        values.investigation_type = investigation_type;
        values.has_collaboration = (formData.collaboration_list) ? 'Y' : 'N';

        investigationApiService.save(values).then(res => {
            message.success("Votre réponse est bien enregistré. Merci pour votre collaboration.");
            onCancel();
        }).catch(err => {
            message.error("An error occurred! Please try again.");
        });
    }

    const handleChangeCollaborations = (event) => {
        event.preventDefault();
        const found = collaborations.find(item => item === event.target.value);
        if (!found) {
            collaborations.push(event.target.value);
        } else {
            let idx = collaborations.findIndex(item => item === event.target.value);

            if (idx !== -1) {
                collaborations.splice(idx, 1);
            }
        }
        setCollaborations(collaborations);
    }

    return (
        <SweetAlert show={showInvestigation}
                    showCancel={false}
                    showConfirm={showConfirm}
                    cancelBtnBsStyle="default"
                    title={'Enquête'}
                    customClass="gx-sweet-alert-top-space"
                    onCancel={() => {
                        onCancel();
                    }}
                    onConfirm={() => {
                        onFinish();
                    }}
        >
            {/*<p><strong>TAWA vous aide à décrocher des collaborations avec des marques tunisiennes et internationales. Notre équipe vous contactera pour vous communiquer les détails.</strong></p>*/}

            <Form
                className={"gx-p-3"}
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
            >
                <Form.Item
                    name="need_formation"
                    rules={[
                        {
                            required: true,
                            message: 'Please fill this question!',
                        },
                    ]}
                >
                    <div>
                        <h5>Souhaitez vous avoir des formations en marketing digital ?</h5>
                        <RadioGroup>
                            <Radio value={'Y'}>Oui</Radio>
                            <Radio value={'N'}>Non</Radio>
                        </RadioGroup>
                    </div>
                </Form.Item>

                <Form.Item
                    name="collaboration_list"
                    rules={[{ required: false}]}
                >
                    <h5>Vous avez déjà collaboré avec des {investigation_type === 'advertiser' ? 'influenceurs' : 'marques'} ? Citez les
                        <Input />
                    </h5>
                </Form.Item>
                <Form.Item
                    name="collaboration_types"
                    rules={[
                        {
                            required: true,
                            message: 'Please fill this question!',
                        },
                    ]}
                >
                    <div>
                        <h5>Vous préférez quelles collaborations ?</h5>
                        <Checkbox key={'0'} value={'unboxing'} onChange={handleChangeCollaborations}>Unboxing</Checkbox>
                        <Checkbox key={'1'} value={'competition_games'} onChange={handleChangeCollaborations}>Jeux Concours</Checkbox>
                    </div>
                </Form.Item>
                <div>
                    <Button type="primary" htmlType="submit">Envoyer</Button>
                    <Button type="secondary" htmlType="button" onClick={() => {
                        onCancel();
                    }}>Annuler</Button>
                </div>
            </Form>
        </SweetAlert>
    )
}
