import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "../../util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {useSelector} from "react-redux";
import siteConfig from "../../config/site.config";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  let {pathname} = useSelector(({common}) => common);
  let {navStyle, themeType} = useSelector(({settings}) => settings);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <>
      <SidebarLogo/>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile/>
          <AppsNavigation/>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            {Boolean(siteConfig.IsTalent === 'true') ?
              <MenuItemGroup key="main" className="gx-menu-group">
                {/*<Menu.Item key="/talent/engagement-rate">*/}
                {/*  <i className="icon icon-timeline"/>*/}
                {/*  <Link to="/talent/engagement-rate">*/}
                {/*    <span><IntlMessages*/}
                {/*      id="sidebar.engagementRate"/></span></Link>*/}
                {/*</Menu.Item>*/}
                <Menu.Item key="mes-collaborations">
                  <i className="icon icon-data-display"/>
                  <Link to= {siteConfig.prefixMe  + "/mes-collaborations"}>
                    <span><IntlMessages
                      id="sidebar.collaborations"/></span></Link>
                </Menu.Item>
                <Menu.Item key="collaborations-public">
                  <i className="icon icon-notification"/>
                  <Link to={siteConfig.prefixMe + "/collaborations-public"}>
                    <span><IntlMessages
                      id="sidebar.collaborations-public"/></span></Link>
                </Menu.Item>
              </MenuItemGroup>:''
            }
            {Boolean(siteConfig.IsAD === 'true') ?
              <MenuItemGroup key="main" className="gx-menu-group">
                <Menu.Item key="public-compains">
                  <i className="icon icon-timeline"/>
                  <Link to={siteConfig.prefixMe +"/public-compains"}>
                    <span><IntlMessages
                      id="sidebar.public-compain"/></span></Link>
                </Menu.Item>
                <Menu.Item key="private-compains">
                  <i className="icon icon-data-display"/>
                  <Link to={siteConfig.prefixMe +"/private-compains"}>
                    <span><IntlMessages
                      id="sidebar.private-compain"/></span></Link>
                </Menu.Item>
                <Menu.Item key="engagement-info">
                  <i className="icon icon-auth-screen"/>
                  <Link to={siteConfig.prefixMe +"/engagement-info"}>
                    <span><IntlMessages
                        id="sidebar.engagement-info"/></span></Link>
                </Menu.Item>
              </MenuItemGroup>:''
            }
            {Boolean(siteConfig.IsAdmin === 'true') ?
                <MenuItemGroup key="main" className="gx-menu-group">
                  <Menu.Item key="dashboard">
                    <i className="icon icon-dasbhoard"/>
                    <Link to={siteConfig.prefixMe +"/dashboard"}>
                    <span><IntlMessages
                        id="sidebar.dashboard"/></span></Link>
                  </Menu.Item>
                  <Menu.Item key="all-compains">
                    <i className="icon icon-timeline"/>
                    <Link to={siteConfig.prefixMe +"/all-compains"}>
                    <span><IntlMessages
                        id="sidebar.campaigns"/></span></Link>
                  </Menu.Item>
                  <Menu.Item key="all-talents">
                    <i className="icon icon-profile2"/>
                    <Link to={siteConfig.prefixMe +"/all-talents"}>
                    <span><IntlMessages
                        id="sidebar.talents"/></span></Link>
                  </Menu.Item>
                  <Menu.Item key="talents-scrapping">
                    <i className="icon icon-visits"/>
                    <Link to={siteConfig.prefixMe +"/talents-scrapping"}>
                    <span><IntlMessages
                        id="sidebar.talents-scrapping"/></span></Link>
                  </Menu.Item>
                  <Menu.Item key="advertisers">
                    <i className="icon icon-data-display"/>
                    <Link to={siteConfig.prefixMe +"/all-advertisers"}>
                    <span><IntlMessages
                        id="sidebar.advertisers"/></span></Link>
                  </Menu.Item>
                  <Menu.Item key="agencies">
                    <i className="icon icon-apps"/>
                    <Link to={siteConfig.prefixMe +"/all-agencies"}>
                    <span><IntlMessages
                        id="sidebar.agency"/></span></Link>
                  </Menu.Item>
                  <Menu.Item key="all_invitations">
                    <i className="icon icon-check-square-o"/>
                    <Link to={siteConfig.prefixMe +"/all_invitations"}>
                    <span><IntlMessages
                        id="sidebar.invitations"/></span></Link>
                  </Menu.Item>
                  <Menu.Item key="settings">
                    <i className="icon icon-icon"/>
                    <Link to={siteConfig.prefixMe +"/settings"}>
                    <span><IntlMessages
                        id="sidebar.settings"/></span></Link>
                  </Menu.Item>
                  <Menu.Item key="engagement-info">
                    <i className="icon icon-auth-screen"/>
                    <Link to={siteConfig.prefixMe +"/engagement-calculator"}>
                    <span><IntlMessages
                        id="sidebar.engagement-info"/></span></Link>
                  </Menu.Item>
                </MenuItemGroup>:''
            }
            {Boolean(siteConfig.IsAgency === 'true') ?
                <MenuItemGroup key="main" className="gx-menu-group">
                  <Menu.Item key="advanced-search">
                    <i className="icon icon-visits"/>
                    <Link to={siteConfig.prefixMe +"/advanced-search"}>
                    <span><IntlMessages
                        id="sidebar.advanced-search"/></span></Link>
                  </Menu.Item>
                  <Menu.Item key="engagement-info">
                    <i className="icon icon-auth-screen"/>
                    <Link to={siteConfig.prefixMe +"/engagement-calculator"}>
                    <span><IntlMessages
                        id="sidebar.engagement-info"/></span></Link>
                  </Menu.Item>
                </MenuItemGroup>:''
            }
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

