import React from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import {Button, Card, Form, Input} from "antd";
import {makeStyles} from "@material-ui/core/styles";
import IntlMessages, {translateMsg} from "../../../../util/IntlMessages";
import {message} from "antd/lib/index";
import {useIntl} from "react-intl";
import businessSectorsApiService from "../../../../util/services/BusinessSectorsApiService";

const AddBusinessSector = ({enable_add, disableDrawerAdd}) => {
    const [form] = Form.useForm();
    const intl = useIntl();

    const [state, setState] = React.useState({
        right: enable_add,
    });
    const useStyles = makeStyles({
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    });

    const classes = useStyles();

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };


    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            disableDrawerAdd(false)
            return;
        }
        setState({...state, [anchor]: open});
        disableDrawerAdd(false)

    };

    const onFinish = values => {
        const formProfile = values
        businessSectorsApiService.save(formProfile).then(res => {
            message.success('Business sector created with success', 3)
            disableDrawerAdd(false)

        }).catch(err => {
            const {data} = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    };


    const FormBusinessSector = (anchor) => {
        return (<div
                className={clsx(classes.list, {
                    [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                })}
                role="presentation"
            >
                <Card className="gx-card" title="Add category">
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="add"
                        onFinish={onFinish}
                        scrollToFirstError
                    >
                        <Form.Item
                            name="label"
                            label="Label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your business sector label!',
                                },
                            ]}

                        >
                            <Input d/>
                        </Form.Item>
                        <Form.Item
                            name="value"
                            label="Code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your business sector code!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                                save
                            </Button>
                            <Button type="secondary" onClick={() => disableDrawerAdd(false)}><IntlMessages
                                id="drawer.cancel"/></Button>
                        </Form.Item>
                    </Form>
                </Card>

            </div>
        );
    };

    return (
        <Drawer anchor={'right'} open={state['right']}
                onClose={toggleDrawer('right', false)}>
            {FormBusinessSector('right')}
        </Drawer>
    )
};

export default AddBusinessSector;
