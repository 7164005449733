import React from "react";
import siteConfig from "../../../config/site.config";
import {Link} from "react-router-dom";

const CollaborationPublicItems =({data})=> {
  return (
      <>
        <div className="gx-news-item">
          <div className="gx-news-thumb">
            <Link to={siteConfig.prefixMe + "/public-collaborations/"+ data?.compain_id}>
              <div className="gx-news-thumb"><img className="gx-width-175 gx-rounded-lg" src={siteConfig.AppConfig.baseStyleImageUrl + data?.advertiser?.profile_efile_id} alt="..."/></div>
            </Link></div>
          <div className="gx-news-content">
            <h4 className="gx-mt-0">{data?.advertiser?.advertiser_name}</h4>
            <p className="gx-mb-2">{data?.url}</p>
            <div className="gx-news-tags-row">
              <div className="gx-news-tags-left">
                <p className="gx-text-grey gx-mb-0 gx-text-truncate"><i
                    className={`icon icon-tag-new gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle gx-text-light-grey`}/>{data?.description}
                </p>
              </div>
              <div className="gx-news-tags-right">
                <p className="gx-text-primary gx-pointer gx-mb-0"><Link to={siteConfig.prefixMe + "/public-collaborations/"+ data?.compain_id}>Ready Full Story</Link>
                  <i className={`icon icon-long-arrow-right gx-fs-xl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle`}/>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className={"hr-separator"} />
      </>
  );
}

export default CollaborationPublicItems;
