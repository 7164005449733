import BaseApiService from "../BaseApiService";

class BusinessSectorsApiService extends BaseApiService {
  constructor() {
    super('business_sector');
  }
}

const businessSectorsApiService = new BusinessSectorsApiService();

export default businessSectorsApiService;
