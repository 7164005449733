import React from "react";
import Widget from "../../Widget";

const Biography = ({advertiser_info}) => {
  return (
    <Widget styleName="gx-card-profile">
      <div className="ant-card-head">
        <span className="ant-card-head-title gx-mb-2">Biography</span>
      </div>
      <p className={"text-grey gx-fs-sm gx-mb-0"}>{advertiser_info?.bio}</p>
    </Widget>
  )
}

export default Biography;
