import React from "react";

const AppsNavigation = () =>
  <ul className="gx-app-nav">
    {/*<li><i className="icon icon-search-new"/></li>*/}
    {/*<li><i className="icon icon-notification"/></li>*/}
    {/*<li><i className="icon icon-chat-new"/></li>*/}
  </ul>;

export default AppsNavigation;
