import React, {Fragment, useEffect} from "react";
import {Col, message, Row} from "antd";
import CompainItem from "./CompainItem";
import {Link} from "react-router-dom";
import advertiserApiService from "../../util/services/AdvertisersApiService";
import siteConfig from "../../config/site.config";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages, {translateMsg} from "../../util/IntlMessages";
import {isMyAccount} from "../../util/helpers";
import FBValidatorConnector from "../../components/FBComponents/FBValidatorAccount";
import {useIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "./loader";

const CompainManager = (props) => {
    const [publicCompain, setPublicCompain] = React.useState([]);
    const [advertiser, setAdvertiser] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [is_valid, setIsValid] = React.useState(localStorage.getItem('is_valid') || null);
    const ad_id = localStorage.getItem('ad_id') ? localStorage.getItem('ad_id') : null
    const [showAlert, setShowAlert] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0)
    const [offset, setOffset] = React.useState(0)

    const intl = useIntl();

    useEffect(() => {
        getPublicCompains(page)
    }, [ad_id]);

    const getPublicCompains = (page) => {
        advertiserApiService
            .getPublicComppains({
                advertiser_id: ad_id,
                limit: 10,
                page: page
            })
            .then(res => {
                setIsLoading(false);
                setPublicCompain(res.data.data || []);
                setPages(res.data.pages);
                setOffset(res.data.offset);
                setCount(res.data.count)
            });
    }
    useEffect(() => {
        advertiserApiService
            .getMe(ad_id)
            .then(res => {
                setIsLoading(false);
                setAdvertiser(res.data.data.profile || null);
            });
    }, [ad_id]);

    const onChangePage = (event, newPage) => {
        let current_page = newPage
        setPage(current_page)
        getPublicCompains(current_page)
    };

    const callbackFBConnector = (data) => {
        setAdvertiser({...advertiser, ...{is_valid: 'Y'}});
        message.success(translateMsg("AccountValidatedWithSuccess", intl), 3, () => {
            window.location.reload();
        });
    };

    const disableAlert = () => {
        setShowAlert(false)
    };

    const updateMe = () => {
        getPublicCompains(1)
    };

    return (
        <div>
            {!isLoading ?
                <>
                    {is_valid === 'Y' ?
                        <Fragment>
                            {/*<Widget>*/}
                            {/*    <div className="gx-table-responsive">*/}
                            {/*        <Widget styleName="gx-card-profile">*/}
                            {/*            <div className="ant-card-head" style={{margin: "0 0 0% 40%"}}>*/}
                            {/*                <h1 className="ant-card-head-title gx-mb-2"*/}
                            {/*                    style={{fontWeight: '700'}}>Poster des Opportunités gratuites</h1>*/}
                            {/*            </div>*/}
                            {/*            <h2 style={{textAlign: "center"}}>*/}
                            {/*                (TAWA vous donne accès à 3 opportunités gratuites*/}
                            {/*                pour essayer l’impact du marketing d’influence*/}
                            {/*                Postez votre opportunité de collaboration, des influenceurs vous répondrons*/}
                            {/*                (3 réponses gratuites par opportunité))*/}
                            {/*            </h2>*/}
                            {/*        </Widget>*/}
                            {/*    </div>*/}
                            {/*</Widget>*/}
                            <div className={"gx-mb-3"} style={{textAlign: "right"}}>
                                <button className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-text-uppercase ">
                                    <Link to={siteConfig.prefixMe + "/add-public-compain"}>Ajouter</Link>
                                </button>
                            </div>
                            <Row>
                                {publicCompain.map((compain, index) => (<Col key={index} xl={6} md={8} sm={12} xs={24}>
                                        <CompainItem key={index} grid compain={compain} updateMe={updateMe}/>
                                    </Col>
                                ))}
                            </Row>
                            <Pagination style={{float: "right"}} count={pages} color="primary"
                                        onChange={onChangePage}/>

                        </Fragment>
                        :
                        <SweetAlert show={showAlert}
                                    showCancel={false}
                                    showConfirm={false}
                                    title={<IntlMessages id="ad.accountNotValid"/>}
                        >
                            {advertiser?.is_valid !== "Y" && isMyAccount(advertiser) ?
                                <div className={"gx-mb-3"} style={{textAlign: "center"}}>
                                    <p>Connectez-vous en utilisant Instagram pour vérifier votre compte</p>
                                    <Button onClick={disableAlert}>
                                        <FBValidatorConnector profileType={"advertiser"}
                                                              callbackFBConnector={(data) => {
                                                                  callbackFBConnector(data);
                                                              }}/>
                                    </Button>
                                </div> : ''}
                        </SweetAlert>}
                </> :
                <Loader/>
            }

        </div>
    );
};

export default CompainManager;


