import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

const TalentInfo = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={asyncComponent(() => import('./Engagement/'))}/>
  </Switch>
);

export default TalentInfo;
