import React from "react";
import Widget from "../Widget";
import {Table} from "antd";

export const EngagementRateInfo = () => {

  const dataSource = [
    {
      "key": 1,
      "1": '5.6%',
      "2": '2.43%',
      "3": '2.15%',
      "4": '2.05%',
      "5": '1.97%',
    },
  ];

  const columns = [
    {
      title: '1k-5k',
      dataIndex: '1',
      key: '1',
    },
    {
      title: '6k-20k',
      dataIndex: '2',
      key: '2',
    },
    {
      title: '21k-100k',
      dataIndex: '3',
      key: '3',
    },
    {
      title: '101k-1M',
      dataIndex: '4',
      key: '4',
    },
    {
      title: '1M+',
      dataIndex: '5',
      key: '5',
    },
  ];

  return (<Widget styleName="gx-card-profile">
    <div className="ant-card-head">
      <span className="ant-card-head-title gx-mb-2">Engagement Rate Average</span>
    </div>
    <Table dataSource={dataSource} columns={columns} pagination={false} />
  </Widget>);
}
