import BaseApiService from "../BaseApiService";

class CategoriesApiService extends BaseApiService {
  constructor() {
    super('categorie');
  }
}

const categoriesApiService = new CategoriesApiService();

export default categoriesApiService;
