import React, {useEffect, Fragment} from "react";
import talentApiService from "../../../util/services/TalentsApiService";
import * as style from'../../talent-info/Engagement/style.css'
import siteConfig from "../../../config/site.config";
import  {DatePicker, Form, Row, Col, Input, Button ,Card, Table,Upload, message } from 'antd';
import {InboxOutlined, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import { SocialIcon } from 'react-social-icons';
import moment from "moment";
import {post} from "axios";
import compainApiService from "../../../util/services/CompainApiService";
import {useHistory}  from 'react-router-dom';
import {Modal} from "antd"

const AddCompain = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const {TextArea} = Input;
  const [Talents, setTalents] = React.useState([]);
  const [TalentsInvited, setTalentsInvited] = React.useState([]);
  const [advertiser_id, setAdvertiser_id] = React.useState();
  const [compain_efile_id, setCompain_efile_id] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [previewPicture, setPreviewPicture] = React.useState('');

  useEffect(() => {
    let filter = [];
    filter.push({
      operator: 'and',
      conditions: [
        {
          field: 'is_valid',
          operator: 'eq',
          value: 'Y'
        }
      ]
    });
    talentApiService
      .find({
        limit: -1,
        filter: filter})
      .then(res => {
        setIsLoading(false);
        setTalents(res.data.data || null);
      });
  }, [advertiser_id]);

  const InfoTalentsColumns = [
    {
      title: 'Talent Picture',
      dataIndex: 'profile_efile_id',
      render: (text, record) => {
        return <div className="gx-flex-row gx-align-items-center">
          <img className="gx-rounded-circle gx-size-30 gx-mr-2" src={siteConfig.AppConfig.baseStyleImageUrl + text} alt=""/>
        </div>
      },
    },
    {
      title: 'Talent Name ',
      render: (text, record) => {
        return <div className="gx-flex-row gx-align-items-center">
          <span className="gx-mb-0">{record?.influencer_name}</span>
        </div>
      },
    },
    {
      title: 'Instagram Account',
      dataIndex: 'instagram_url',
      render: (text, record) => {
        return <span className="gx-text-grey"><a href={record?.instagram_url} target="_blank"><SocialIcon url={record?.instagram_url} /></a></span>
      },

    },
    {
      title: 'Actions',
      dataIndex: 'status',
      render: (text, record) => {
        return <span className="gx-text-primary gx-pointer">
          <button className="ant-btn ant-btn-success"  onClick={() => inviteTalent(record)}>Inviter</button></span>
      },
    },

  ];

  const InfoTalentsInvitedColumns = [
    {
      title: 'Talent ',
      dataIndex: 'profile_efile_id',
      render: (text, record) => {
        return <div className="gx-flex-row gx-align-items-center">
          <img className="gx-rounded-circle gx-size-30 gx-mr-2" src={siteConfig.AppConfig.baseStyleImageUrl + text} alt=""/>
        </div>
      },
    },
    {
      title: 'Talent Name ',
      render: (text, record) => {
        return <div className="gx-flex-row gx-align-items-center">
          <span className="gx-mb-0">{record?.influencer_name}</span>
        </div>
      },
    },
    {
      title: 'Instagram Account',
      dataIndex: 'instagram_url',
      render: (text, record) => {
        return <span className="gx-text-grey"><a href={record?.instagram_url} target="_blank"><SocialIcon url={record?.instagram_url} /></a></span>
      },

    },
    {
      title: 'Actions',
      dataIndex: 'status',
      render: (text, record) => {
        return <span className="gx-text-primary gx-pointer">
          <button className="ant-btn ant-btn-success"  onClick={() => deleteTalent(record)}>Delete</button></span>
      },
    },

  ];

  const onFinish = values => {
    const ad_id = localStorage.getItem('ad_id')? localStorage.getItem('ad_id'): null
    const influencers_ids = []
    if (TalentsInvited && TalentsInvited.length !== 0) {
      TalentsInvited.forEach(item_talent => {
        influencers_ids.push(item_talent.influencer_id)
      })
    }
    let FormData = values
    FormData.start_date_at = moment(values.start_date_at).format('YYYY-MM-DD')
    FormData.end_date_at = moment(values.end_date_at).format('YYYY-MM-DD')
    FormData.advertiser_id = ad_id
    FormData.category = 'public'
    FormData.influencers_ids = influencers_ids
    FormData.image_efile_id = compain_efile_id
    compainApiService.addCompain(FormData).then(res => {
      if (res.data.success === true) {
        message.success('Compaign Created With success');
        history.push('/me/public-compains')
      } else {
        message.error(res.data.message);
      }
    })
  };

  const handleUploadClick = event => {
    const file = event.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('file', file);
      return post(siteConfig.AppConfig.fileUploadUrl, formData, {
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
        timeout: 30000,
      }).then(res => {
        setCompain_efile_id(res.data.data)
      })
    }
  };
  const inviteTalent = value => {
    setTalentsInvited([
      ...TalentsInvited,
      value
    ]);
    let talent_updated = Talents
    let idx = talent_updated.findIndex(item_talent => item_talent === value)
    talent_updated.splice(idx,1)
    setTalents([
      ...talent_updated
    ]);
  }

  const deleteTalent = value => {
    setTalents([
      ...Talents,
      value
    ]);
    let talent_invited_updated = TalentsInvited
    let idx = talent_invited_updated.findIndex(item_talent => item_talent === value)
    talent_invited_updated.splice(idx,1)
    setTalentsInvited([
      ...talent_invited_updated
    ]);
  }
 const handleCancel = () => {
  setPreviewVisible(false)
 }
  const handlePreview = (file) => {
    console.log(file)
    setPreviewPicture(file.url || file.thumbUrl)
    setPreviewVisible(true)
  };
 const  compainImage= [{
    uid: -1,
    name: 'compain image',
    status: 'done',
    url: siteConfig.AppConfig.baseStyleImageUrl + compain_efile_id,
  }]

 const handleChange = () => {
   setCompain_efile_id(null)
 }
  return (
    <div>
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item style={{margin:"0 0 0 95%"}}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="compain_name"
            rules={[
              {
                required: true,
                message: 'Please input your Compaign Name!',
              },
            ]}
          >
            <Input placeholder= "Compaign Name" />
          </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please input your Compaign Description!',
                },
              ]}
            >
              <TextArea rows={4} placeholder= "Description"/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="start_date_at"
              rules={[
                {
                  required: true,
                  message: 'Please input your Compaign Start Date!',
                },
              ]}
            >
              <DatePicker className="gx-mb-3 gx-w-100" placeholder= "Start date"/>
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24} className={"ml-2"}>
            <Form.Item
              name="end_date_at"
              rules={[
                {
                  required: true,
                  message: 'Please input your Compaign End Date!',
                },
              ]}
            >
              <DatePicker className="gx-mb-3 gx-w-100"  placeholder= "End date"/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="url"
              rules={[
                {
                  required: true,
                  message: 'Please input your Compaign Url!',
                },
              ]}
            >
              <Input  placeholder= "Compaign Url"/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="offre"
              rules={[
                {
                  required: true,
                  message: 'Please input your Compaign offre!',
                },
              ]}
            >
              <TextArea rows={4} placeholder= "Offre"/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={6} sm={24} xs={24} className={"ml-2"}>
            <Form.Item
                label={"Compaign Image"}>
              <div className={"group-file-upload"}>
                <input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                />
                <Button icon={<UploadOutlined />} onClick={() => { document.getElementById('contained-button-file').click() }}>Click to upload</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        {compain_efile_id ?
          <Row>
            <Card title="Compain Picture" className="gx-card clearfix ml-2">
              <Upload
                listType="picture-card"
                fileList={compainImage}
                onPreview={handlePreview}
                onChange={handleChange}
              >
              </Upload>
              <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{width: '100%'}} src={previewPicture}/>
              </Modal>
            </Card>
          </Row>: ''
        }
      </Form>
      {/*<Widget*/}
      {/*  title={*/}
      {/*    <h2 className="h4 gx-text-capitalize gx-mb-0">*/}
      {/*      List Talents Invited</h2>*/}
      {/*  }>*/}
      {/*  <div className="gx-table-responsive">*/}
      {/*    <Table className="gx-table-no-bordered" columns={InfoTalentsInvitedColumns} dataSource={TalentsInvited} pagination={true}*/}
      {/*           size="small"/>*/}
      {/*  </div>*/}
      {/*</Widget>*/}
      {/*<Widget*/}
      {/*  title={*/}
      {/*    <h2 className="h4 gx-text-capitalize gx-mb-0">*/}
      {/*      List Talents</h2>*/}
      {/*  }>*/}
      {/*  <div className="gx-table-responsive">*/}
      {/*    <Table className="gx-table-no-bordered" columns={InfoTalentsColumns} dataSource={Talents} pagination={true}*/}
      {/*           size="small"/>*/}
      {/*  </div>*/}
      {/*</Widget>*/}
    </div>
  );
};

export default AddCompain;


