
const gender = [
  {
    value: 'M',
    label: 'homme'
  },
  {
    value: 'F',
    label: 'femme'
  },
];

export const tawaGender = gender;
