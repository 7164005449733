import React, {useEffect, useState} from "react";
import Widget from "../Widget";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const FollowersEvolution = ({stats}) => {

    const [chartData, setChartData] = useState([]);
    useEffect(() => {
        let data = [];
        if (stats && stats.length) {
            stats.forEach(s => {
                data.push({
                    name: s.date,
                    followers: s.followers_count,
                })
            });
            setChartData(data.reverse());
        }
    }, [stats]);

    return (
        <Widget title="MONTHLY FOLLOWERS EVOLUTION" styleName="gx-card-profile-sm">
            {/*<ResponsiveContainer width="100%" height={180}>*/}
            {/*    <AreaChart data={chartData}*/}
            {/*               margin={{top: 0, right: 0, left: 0, bottom: 0}}>*/}
            {/*        <Tooltip/>*/}
            {/*        <XAxis dataKey="name"/>*/}
            {/*        <defs>*/}
            {/*            <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">*/}
            {/*                <stop offset="5%" stopColor="#38AAE5" stopOpacity={0.8}/>*/}
            {/*                <stop offset="95%" stopColor="#F5FCFD" stopOpacity={0.8}/>*/}
            {/*            </linearGradient>*/}
            {/*        </defs>*/}
            {/*        <CartesianGrid strokeDasharray="5 10" />*/}
            {/*        <XAxis dataKey="followers" />*/}
            {/*        <YAxis dataKey="followers" />*/}
            {/*        <Tooltip />*/}
            {/*        <Area dataKey='followers' type="monotone" strokeWidth={2} stackId="2" stroke='#10316B' fill="url(#color15)"*/}
            {/*              fillOpacity={1}/>*/}
            {/*    </AreaChart>*/}
            {/*</ResponsiveContainer>*/}
            <ResponsiveContainer width="100%" height={180}>
                <AreaChart data={chartData}
                           margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <defs>
                        <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="5%" stopColor="#4ECDE4" stopOpacity={0.9}/>
                            <stop offset="95%" stopColor="#06BB8A" stopOpacity={0.9}/>
                        </linearGradient>
                    </defs>
                    <Area dataKey="followers" strokeWidth={0} stackId="2" stroke='#4D95F3' fill="url(#color4)"
                          fillOpacity={1}/>
                </AreaChart>
            </ResponsiveContainer>
        </Widget>
    )
}

export default FollowersEvolution;
