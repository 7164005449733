import React, {useEffect, useState} from "react";
import FacebookLogin from "react-facebook-login";
import {Modal, Button, Alert, message} from 'antd';
import { useIntl } from 'react-intl';
import fbApiService from "../../util/services/FBApiService";
import siteConfig from "../../config/site.config";
import {translateMsg} from "../../util/IntlMessages";

const FBValidatorConnector = ({profileType, callbackFBConnector}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fbResponse, setFBResponse] = useState(null);
    const [fbBusinessAccounts, setFBBusinessAccounts] = useState(null);

    const intl = useIntl();

    const showModal = (data) => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (fbBusinessAccounts && fbBusinessAccounts.length === 1) {
            const account = fbBusinessAccounts[0];
            if (account && account.instagram_business_account && account.instagram_business_account.ig_id) {
                // setIsModalVisible(false);
                // selectAccount(account);
            }
        } else {
            closeModal();
        }
    }, [fbBusinessAccounts]);

    const responseFacebook = response => {
        if (response && response.accessToken) {
            setFBResponse(response);

            fbApiService.setUserFbData(response);
            fbApiService.setToken(response.accessToken);
            fbApiService.requestBusinessAccounts().then(res => {
                if (res && res.data) {
                    let accounts = [];
                    if (res.data.data) {
                        res.data.data.forEach(fb_account => {
                            if (fb_account && fb_account.instagram_business_account) {
                                accounts.push(fb_account);
                            }
                        })
                    }

                    if (!accounts.length) {
                        message.error("Can't find your Instagram for business account! Please try again", 5000);
                        return;
                    }

                    fbApiService.setBusinessAccounts(accounts || null);

                    fbApiService.businessAccounts.forEach(account => {
                        fbApiService.getIgAccounts(account).then(data_ig => {
                            const ig_data = data_ig.data;
                            const idx = fbApiService.businessAccounts.findIndex(a => a.instagram_business_account.id === ig_data.id);
                            if (idx !== -1) {
                                fbApiService.businessAccounts[idx].instagram_business_account = ig_data;
                            }
                            setFBBusinessAccounts(JSON.parse(JSON.stringify(fbApiService.businessAccounts)));
                        });
                    });
                    setFBBusinessAccounts(fbApiService.businessAccounts);
                    if (fbApiService.businessAccounts && fbApiService.businessAccounts.length > 1) {
                        // showModal();
                    }
                    showModal();
                }
            });
        }
    };

    const selectAccount = (selected_account) => {
        fbApiService.validateIGAccount(profileType, selected_account).then(res => {
            closeModal();
            if (typeof callbackFBConnector === "function") {
                callbackFBConnector(res.data);
            }
        }).catch(err => {
            const response = (err.response && err.response.data) ? err.response && err.response.data : {};
            if (response && response.messages) {
                response.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl), 10);
                });
            }
        });
    }

    const renderIGOption = (account) => {
        return (<li className={"item-ig-select-account"} key={account.id} onClick={() => selectAccount(account)} >
            {/*<div className={"account-business"}>
        {account?.picture ? <img width={50} alt={account.name} className={"img-fluid"} src={account.picture.data.url} /> : ''}
        <span>{account.name}</span>
      </div>*/}
            {(account?.instagram_business_account && account.instagram_business_account.username) ?
                <div className={"sub-ig-account"}>
                    <img width={50} alt={account.name} className={"img-fluid"} src={account.instagram_business_account.profile_picture_url} />
                    <span>{account.instagram_business_account.name}</span>
                </div>
                : ''}
        </li>);
    }

    return (
        <>
            <div className="tawa-app-fb-connector">
                <span style={{display: "inline-block"}}><FacebookLogin
                    icon="fa-facebook"
                    textButton={"Login with Facebook"}
                    cssClass={"btn-login-facebook"}
                    appId={siteConfig.facebook.app_id}
                    autoLoad={false}
                    callback={responseFacebook}
                    scope={'pages_show_list,instagram_basic,pages_read_engagement,public_profile,email'}
                /></span>
            </div>
            <Modal footer={false} title="Login with Facebook" visible={isModalVisible} onOk={handleOk} onCancel={closeModal}>
                {fbBusinessAccounts && fbBusinessAccounts.length ?
                    <div>
                        <div className={"pre-options-select-account"}>
                            <label>Select your business account:</label>
                        </div>
                        <ul className={"select-account-fb-list"} >
                            {fbBusinessAccounts ? fbBusinessAccounts.map(renderIGOption) : ''}
                        </ul>
                    </div>
                    :
                    <div>
                        <Alert message="Can't find your Instagram for business account! Please try again" type="error" />
                    </div>
                }
            </Modal>
        </>
    );
};

export default FBValidatorConnector;
