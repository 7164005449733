
const followers_count = [
  {
    value: [0 ,20000],
    label: '-20K'
  },
  {
    value: [20000, 50000],
    label: '20K - 50k'
  },

  {
    value: [50000 ,100000],
    label: '50k - 100k',
  },
  {
    value: [100000 ,300000],
    label: '100k - 300k'
  },
  {
    value: [300000, 500000],
    label: '300k - 500K'
  },
  {
    value: [500000, 1000000],
    label: '500K - 1M'
  },
  {
    value: [1000000, 1000000000000],
    label: '+1M'
  }
];

export const tawaFollowers_count = followers_count;
