import React, {useEffect, useState} from "react";
import {abbrNum, pourcentNum} from "../../../util/helpers";
import IconWithTextCard from "../../Metrics/IconWithTextCard";
import {Col, Row} from "antd";

const HeaderProfileIGData  = ({ig_data}) =>{
    return (
        <>
            {ig_data ?
                <Row className="gx-follower-list">
                        <Col className="followers-card gx-follower-title gx-fs-lg gx-font-weight-medium gx-mr-2">
                            <IconWithTextCard icon="dasbhoard" iconColor="geekblue" title={abbrNum(ig_data?.followers_count, 2)} subTitle="Followers"/>
                        </Col>
                        <Col className="following-card gx-follower-title gx-fs-lg gx-font-weight-medium gx-mr-2">
                            <IconWithTextCard icon="timeline" iconColor="geekblue" title={abbrNum(ig_data?.follows_count, 2)} subTitle="Following"/>
                        </Col>
                        <Col className="engagement-card gx-follower-title gx-fs-lg gx-font-weight-medium gx-mr-2">
                            <IconWithTextCard icon="revenue-new" iconColor="geekblue" title={pourcentNum(ig_data?.engagement_rate)} subTitle="Engagement Rate"/>
                        </Col>
                        <Col className="likes-card gx-follower-title gx-fs-lg gx-font-weight-medium gx-mr-2">
                            <IconWithTextCard style={{marginLeft: '-20px'}} icon="thumb-up" iconColor="geekblue" title={abbrNum(ig_data?.average_likes, 0)} subTitle="Avg Likes/Post"/>
                        </Col>
                        <Col className="likes-card gx-follower-title gx-fs-lg gx-font-weight-medium">
                            <IconWithTextCard style={{marginLeft: '-20px'}} icon="feedback" iconColor="geekblue" title={abbrNum(ig_data?.average_comments, 0)} subTitle="Avg Comments/Post"/>
                        </Col>
                </Row>
                : ''}
        </>
    )
};

export default HeaderProfileIGData;
