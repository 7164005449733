export const abbrNum = (number, decPlaces) => {
    if (number === null) {
        return 0;
    }
    decPlaces = Math.pow(10, decPlaces);
    const abbrev = ["k", "m", "b", "t"];

    for (let i = abbrev.length - 1; i >= 0; i--) {

        const size = Math.pow(10, (i + 1) * 3);
        if (size <= number) {
            number = Math.round(number * decPlaces / size) / decPlaces;
            if ((number === 1000) && (i < abbrev.length - 1)) {
                number = 1;
                i++;
            }
            number += abbrev[i];
            break;
        }
    }

    return number;
}

export const pourcentNum = (number) => {
    return number? parseFloat(number).toFixed(2): 0;
}

export const isLogged = () => {
    return (localStorage.getItem('user_id') && localStorage.getItem('token'));
}

export const isMyAccount = (profile) => {
    return (
        (isLogged() && profile && profile.user_id && Number(profile.user_id) === Number(localStorage.getItem('user_id')))
    );
}
