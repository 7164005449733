import React, {useEffect} from "react";
import {Avatar, Button, Col, Collapse, DatePicker, Input, message, Popconfirm, Popover, Row} from "antd";
import siteConfig from "../../../config/site.config";
import {useIntl} from "react-intl";
import Pagination from "@material-ui/lab/Pagination";
import Widget from "../../../components/Widget";
import advertiserApiService from "../../../util/services/AdvertisersApiService";
import Loader from "../../compain-manager/loader";
import Switch from "@material-ui/core/Switch";
import {translateMsg} from "../../../util/IntlMessages";
import moment from "moment";
import AddAg from "./add-ag";
import UpdateProfileAgency from "./update-profile-ag";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import BaseApiService from "../../../util/BaseApiService";
const baseApiService = new BaseApiService();
const Panel = Collapse.Panel;
const COLORS = ['#5797fc', '#FA8C16', '#f5222d', '#d9d9d9'];
const dateFormat = 'YYYY/MM/DD';
const Search = Input.Search;
const AgenciesManager = (props) => {
    const [allAgencies, setAllAagencies] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0)
    const [offset, setOffset] = React.useState(0)
    const [showDrawerAdd, setShowDrawerAdd] = React.useState(false);
    const [showDrawerUpdate, setShowDrawerUpdate] = React.useState(false);
    const [advertiser_id, setAdvertiser_id] = React.useState(false);
    const [meta_key, setMeta_key] = React.useState('')
    const [showValidation, setShowValidation] = React.useState([])
    const [currentTrialDate, setCurrentTrialDate] = React.useState(null)
    const [ERStats, setERStats] = React.useState([])
    const [ASearchSTATS, setASearchSTATS] = React.useState([])
    const intl = useIntl();

    useEffect(() => {
        getAllAgencies(page)
    }, [true]);

    const getAllAgencies = (page) => {
        let filter = [];
        filter.push({
            operator: 'and',
            conditions: [
                {
                    field: 'category',
                    operator: 'eq',
                    value: 'AG'
                }
            ]
        });
        advertiserApiService
            .find({
                limit: 8,
                page: page,
                sortBy: 'created_at',
                sortDir: 'DESC',
                filter: filter
            })
            .then(res => {
                setIsLoading(false);
                let agencies = res.data.data || [];
                let ERData = [];
                let ASData = [];
                if (agencies && agencies.length !== 0) {
                    agencies.forEach((item, idx) => {
                        ERData.push([{name: 'Request', value: parseInt(item?.search_count_eng)},  {name: 'rest', value: parseInt(siteConfig.Max_request_eng - item?.search_count_eng)}])
                        ASData.push([{name: 'Request', value: item?.search_count},  {name: 'rest', value: parseInt(siteConfig.Max_request - item?.search_count)}])
                        item.showValidation = showValidation[idx]
                    });
                }
                setAllAagencies(agencies || []);
                setASearchSTATS(ASData);
                setERStats(ERData);
                setPages(res.data?.attributes?.pages || null);
                setOffset(res.data?.attributes?.offset || null);
                setCount(res.data?.attributes?.count || null)
            });
    };

    const onFilterAGByCode = (code) => {
        setMeta_key(code)
        if (code && code !== '' && code.toString().length > 3) {
            advertiserApiService
                .find({
                    limit: -1,
                    meta_key: code.toString(),
                    sortBy: 'created_at',
                    sortDir: 'DESC',
                })
                .then(res => {
                    setIsLoading(false);
                    setAllAagencies(res.data.data || []);
                    setPages(res.data?.attributes?.pages || null);
                    setOffset(res.data?.attributes?.offset || null);
                    setCount(res.data?.attributes?.count || null)
                });
        } else {
            getAllAgencies(1)
        }
    };

    const onChangePage = (event, newPage) => {
        let current_page = newPage
        setPage(current_page)
        getAllAgencies(current_page)
    };
    const EnableDrawerAddProfile = () => {
        setShowDrawerAdd(true)
    }
    const disableDrawerAdd = (event) => {
        setShowDrawerAdd(event);
        getAllAgencies(1)
    };

    const disableDrawerUpdate = (event) => {
        setShowDrawerUpdate(event);
        getAllAgencies(1)
    };

    const EnableDrawerUpdateAd = (advertiser_id) => {
        setAdvertiser_id(advertiser_id)
        setShowDrawerUpdate(true)
    };

    const userMenuOptions = (advertiser_id) => (
        <ul className="gx-user-popover">
            <li onClick={() => EnableDrawerUpdateAd(advertiser_id)}>Update profile</li>
            <Popconfirm title="Are you sure delete this advertiser?" onConfirm={() => confirm(advertiser_id)}
                        onCancel={cancel} okText="Yes"
                        cancelText="No">
                <li>Delete</li>
            </Popconfirm>
        </ul>
    );

    function cancel(e) {
    }

    function confirm(advertiser_id) {
        advertiserApiService.delete({id: advertiser_id}).then(res => {
            message.success('Agency Deleted With success');
                 getAllAgencies(1)
            // baseApiService.deleteUser({id: localStorage.getItem('user_id')}).then(res_user => {
            //     message.success('Advertiser Deleted With success');
            //     getAllAgencies(1)
            // }).catch(err => {
            //     const {data} = err.response;
            //     if (data && data.messages) {
            //         data.messages.forEach(msg => {
            //             message.error(translateMsg(msg, intl));
            //         });
            //     }
            // });
        }).catch(err => {
            const {data} = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    }

    const handleChangeStatusAg = (data) => {
        const formAgency = {is_valid: data.is_valid === 'Y' ? 'N' : 'Y'};
        formAgency.advertiser_id = data.advertiser_id;
        advertiserApiService.update(formAgency).then(res => {
            getAllAgencies(1);
            if (res.data.data.is_valid === 'N') {
                message.success('Agency disabled with success', 3);
            } else {
                message.success('Agency validated with success', 3);
            }
            disableDrawerUpdate(false)

        }).catch(err => {
            const {data} = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    }
    const onChangeTrialDate = (date, dateString, idx) => {
        setCurrentTrialDate(dateString);
        let showValidationStatus = showValidation;
        showValidationStatus[idx] = true;
        setShowValidation(showValidationStatus);
        getAllAgencies(1);
    }

    const resetTrialDate = (idx) => {
        let showValidationStatus = showValidation;
        showValidationStatus[idx] = !showValidationStatus[idx];
        setShowValidation(showValidationStatus);
        getAllAgencies(1);
    }

    const validateTrialDate = (item_agency, idx) => {
        const formAgency = {free_trial_expire_date: currentTrialDate};
        formAgency.advertiser_id = item_agency.advertiser_id;
        advertiserApiService.update(formAgency).then(res => {
            message.success('Trial date updated with success', 3);
            getAllAgencies(1);
            resetTrialDate(idx);
        }).catch(err => {
            const {data} = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    }
    return (
        <div>
            {!isLoading ?
                <>
                    <div className={"gx-mb-3"} style={{textAlign: "right"}}>
                        <button className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-text-uppercase "
                                onClick={EnableDrawerAddProfile}>
                            Add
                        </button>
                    </div>
                    <Search
                        enterButton="Search" size="large"
                        placeholder="input agency name"
                        onSearch={value => onFilterAGByCode(value)}
                        style={{width: 500}}
                    />
                    <Row>
                        {allAgencies.map((item_advertiser, idx) => (
                            <Col key={idx} xl={6} md={8} sm={12} xs={24}>
                                <Widget styleName="gx-card-full" extra={ <Popover content={
                                    <ul className="gx-user-popover">
                                        <li onClick={() => EnableDrawerUpdateAd(item_advertiser?.advertiser_id)}>Update profile</li>
                                        <Popconfirm title="Are you sure delete this agency?" onConfirm={() => confirm(item_advertiser?.advertiser_id)}
                                                    onCancel={cancel} okText="Yes"
                                                    cancelText="No">
                                            <li>Delete</li>
                                        </Popconfirm>
                                    </ul>
                                }>
                                    <i className="icon icon-setting gx-text-grey gx-fs-xl" style={{marginLeft: '32px',marginTop: '9px'}}/>
                                </Popover>}
                                        title={
                                            <>
                                                <div className="gx-py-3">
                                                    <Row>
                                                        <Popconfirm
                                                            title={item_advertiser.is_valid === 'N' ? "Are you sure you want to validate this agency?" : "Are you sure you want to disabled this agency?"}
                                                            onConfirm={() => handleChangeStatusAg(item_advertiser)}
                                                            onCancel={cancel} okText="Yes"
                                                            cancelText="No">
                                                            <Switch
                                                                checked={item_advertiser.is_valid === 'Y'}
                                                                color="primary"
                                                                name="checkedB"
                                                                inputProps={{'aria-label': 'primary checkbox'}}
                                                            />
                                                        </Popconfirm>
                                                    </Row>
                                                </div>
                                            </>

                                        }
                                >
                                    <div className="gx-text-center gx-px-3 gx-pt-3">
                                        <div
                                            className="gx-d-flex gx-justify-content-around gx-align-items-center gx-mb-3">
                                            <Avatar className="gx-size-80"
                                                    src={siteConfig.AppConfig.baseStyleImageUrl + item_advertiser.profile_efile_id}/>
                                        </div>
                                        <div className="gx-mb-3">
                                            <h2>{item_advertiser?.username}</h2>
                                            <p className="gx-text-grey"><a target={"_blank"}
                                                                           href={siteConfig.SignUpAgUrl + '?code=' + item_advertiser?.code}>{item_advertiser?.code}</a>
                                            </p>
                                            {
                                                item_advertiser?.ig_username ?
                                                    <p className="gx-text-grey"><a target={"_blank"}
                                                                                   href={"https://instagram.com/" + item_advertiser?.ig_username}>@{item_advertiser?.ig_username}</a>
                                                    </p>:''
                                            }
                                        </div>
                                        <Collapse className="gx-mb-2">
                                            <Panel header="Requests Evolution" key="1" showArrow={false}>
                                                <div className="gx-py-3">
                                                    <Row>
                                                <ResponsiveContainer width="50%" height={150}>
                                                    <PieChart>
                                                        <Tooltip/>
                                                        <text
                                                            x="50%" className="h6"
                                                            y="50%" textAnchor="middle" dominantBaseline="middle">
                                                            E.R requests
                                                        </text>
                                                        <Pie
                                                            data={ERStats[idx]} dataKey="value"
                                                            cx="50%"
                                                            cy="50%"
                                                            innerRadius={47}
                                                            outerRadius={57}
                                                            fill="#8884d8"
                                                        >
                                                            {
                                                                ERStats[idx]?.map((entry, index) =>
                                                                    <Cell key={index}
                                                                          fill={COLORS[index % COLORS.length]}/>)
                                                            }
                                                        </Pie>
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                <ResponsiveContainer width="50%" height={150}>
                                                    <PieChart>
                                                        <Tooltip/>
                                                        <text
                                                            x="50%" className="h6"
                                                            y="50%" textAnchor="middle" dominantBaseline="middle">
                                                            A.S requests
                                                        </text>
                                                        <Pie
                                                            data={ASearchSTATS[idx]} dataKey="value"
                                                            cx="50%"
                                                            cy="50%"
                                                            innerRadius={47}
                                                            outerRadius={57}
                                                            fill="#8884d8"
                                                        >
                                                            {
                                                                ASearchSTATS[idx]?.map((entry, index) => <Cell key={index}
                                                                                                 fill={COLORS[index % COLORS.length]}/>)
                                                            }
                                                        </Pie>
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                    </Row>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                        <DatePicker className="gx-mb-3 gx-w-100"
                                                    defaultValue={item_advertiser?.free_trial_expire_date ? moment(item_advertiser?.free_trial_expire_date, dateFormat) : ''}
                                                    onChange={(event, dateString) => onChangeTrialDate(event, dateString, idx)}/>
                                        {
                                            item_advertiser.showValidation ?
                                                <>
                                                    <Button onClick={() => resetTrialDate(idx)}>Cancel</Button>
                                                    <Button onClick={() => validateTrialDate(item_advertiser, idx)}
                                                            type="primary">Validate</Button>
                                                </> : ''
                                        }
                                    </div>
                                </Widget>
                            </Col>

                        ))}
                    </Row>
                    {showDrawerAdd ?
                        <AddAg enable_add={showDrawerAdd} disableDrawerAdd={disableDrawerAdd} category='AG'
                               profile_id='4' title="Add agency" is_agency={true}/> : ''

                    }
                    {showDrawerUpdate ?
                        <UpdateProfileAgency enable_update={showDrawerUpdate}
                                             disableDrawerUpdate={disableDrawerUpdate}
                                             advertiser_id={advertiser_id} is_agency={true}/> : ''
                    }
                    <Pagination style={{float: "right"}} count={pages} color="primary"
                                onChange={onChangePage}/>
                </> :
                <Loader/>
            }

        </div>
    );
};

export default AgenciesManager;


