import BaseApiService from "../BaseApiService";
import * as axios from "axios";
import {apiUrl} from "../../config/site.config";

class CompainApiService extends BaseApiService {
  constructor() {
    super('compain');
  }
  addCompain(data) {
    return axios.post(apiUrl + '/' + this.entity_name + '/add', data);
  }
  deleteCompain(compain_id) {
    return axios.delete(apiUrl + '/' + this.entity_name + '/deleteCompain?compain_id='+  compain_id);
  };

  getCompainInfo(compain_id) {
    return axios.get(apiUrl + '/' + this.entity_name + '/getCompainInfo?compain_id='+ compain_id);
  };

  getPublicCompainInfo(params = {}) {
    const params_encoded = (params && Object.keys(params).length) ? encodeURIComponent(JSON.stringify(params)) : '';
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getPublicCompainsInfo/' + params_encoded);
  };
  getInfluencersByCompain(compain_id) {
    return axios.get(apiUrl + '/' + this.entity_name + '/getInfluencersByCompain?compain_id='+ compain_id);
  };
  statsCampaign() {
    return this.setHeaderToken().get(apiUrl + '/' + this.entity_name + '/getStats');
  };
}

const compainApiService = new CompainApiService;

export default compainApiService;
