import React, {useEffect} from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import {Button, Card, Form, Input} from "antd";
import {makeStyles} from "@material-ui/core/styles";
import IntlMessages, {translateMsg} from "../../../../util/IntlMessages";
import categoriesApiService from "../../../../util/services/CategoriesApiService";
import {message} from "antd/lib/index";
import {useIntl} from "react-intl";

const UpdateCategory = ({enable_update, disableDrawerUpdate, category_id}) => {
    const [form] = Form.useForm();
    const [category, setCategory] = React.useState({});

    const intl = useIntl();

    const [state, setState] = React.useState({
        right: enable_update,
    });
    const useStyles = makeStyles({
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    });

    const classes = useStyles();

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const getCategoryInfo = () => {
        categoriesApiService.findById(category_id)
            .then(res => {
                setCategory(res.data.data || null);
            });
    };

    useEffect(() => {
        getCategoryInfo()
    }, []);

    useEffect(() => {
        let formData = {...category, ...{}};
        form.setFieldsValue(formData);
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            disableDrawerUpdate(false)
            return;
        }
        setState({...state, [anchor]: open});
        disableDrawerUpdate(false)

    };

    const onFinish = values => {
        const formCategory = values
        formCategory.category_id = category_id
        categoriesApiService.update(formCategory).then(res => {
            message.success('Category updated with success', 3)
            disableDrawerUpdate(false)

        }).catch(err => {
            const {data} = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    };


    const FormCategory = (anchor) => {
        return (<div
                className={clsx(classes.list, {
                    [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                })}
                role="presentation"
            >
                <Card className="gx-card" title="Update category">
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="add"
                        onFinish={onFinish}
                        scrollToFirstError
                    >
                        <Form.Item
                            name="label"
                            label="Label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your category label!',
                                },
                            ]}

                        >
                            <Input d/>
                        </Form.Item>
                        <Form.Item
                            name="value"
                            label="Code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your category code!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                                save
                            </Button>
                            <Button type="secondary" onClick={() => disableDrawerUpdate(false)}><IntlMessages
                                id="drawer.cancel"/></Button>
                        </Form.Item>
                    </Form>
                </Card>

            </div>
        );
    };

    return (
        <Drawer anchor={'right'} open={state['right']}
                onClose={toggleDrawer('right', false)}>
            {FormCategory('right')}
        </Drawer>
    )
};

export default UpdateCategory;
