import React from "react";
import {Route, Switch} from "react-router-dom";
import Profiles from "./profile-info/index";
import ProfilesAd from "./profile-ad-info/index";
import siteConfig from "../config/site.config";
import TalentInfo from "./talent-info";
import Collaboration from "./talent-info/collaboration";
import PublicCollaborationDetails from "./talent-info/collaboration/collaboration-public/publicCollaborationDetails";
import PublicCollaboration from "./talent-info/collaboration/collaboration-public";
import CollaborationDetails from "./talent-info/collaboration/collaborationDetails";
import MyProfile from "./profile-ad-info/my-profile";
import CompainManager from "./compain-manager";
import PrivateCompainManager from "./compain-manager/private-compain-manager";
import DetailCompain from "./compain-manager/detail-compain";
import AddCompain from "./compain-manager/add-public-compain";
import AddPrivateCompain from "./admin-view/add-private-compain";
import UpdateCompain from "./compain-manager/update-compain";
import TalentProfile from "./talent-info/talent-profile";
import EngagementInfo from "./engagement-info";
import AdminCompainManager from "./admin-view/all-compains";
import TalentsManager from "./admin-view/all-talents";
import AdvertisersManager from "./admin-view/all-advertisers";
import AddPublicCompain from "./admin-view/add-public-compain";
import UpdateCompainAdmin from "./admin-view/update-compains";
import Invitations from "./admin-view/invitations";
import Settings from "./admin-view/settings";
import AddPrivateCampaign from "./compain-manager/add-private-compain";
import Dashboard from "./admin-view/dashboard";
import TalentsScrapping from "./admin-view/talents-scrapping";
import AdvancedSearch from "./agency-view/advanced-search";
import EngagementInfoAgency from "./agency-view/engagement-info";
import AgenciesManager from "./admin-view/all-agencies";

const NotFound = () => (
    <div>
        <h1>404 - Not Found!</h1>a
    </div>
);

const App = ({match, baseUrl}) => (
    <div className="gx-main-content-wrapper">
        {baseUrl === siteConfig.ADUrl ?
            <Switch>
                <Route exact path={siteConfig.prefixMe + `/public-compains`} component={CompainManager}/>
                <Route exact path={siteConfig.prefixMe + `/private-compains`} component={PrivateCompainManager}/>
                <Route exact path={siteConfig.prefixMe + `/detail-compain/:compain_id`} component={DetailCompain}/>
                <Route exact path={siteConfig.prefixMe + `/add-public-compain`} component={AddCompain}/>
                <Route exact path={siteConfig.prefixMe + `/add-private-campaign`} component={AddPrivateCampaign}/>
                <Route exact path={siteConfig.prefixMe + `/update-compain/:compain_id`} component={UpdateCompain}/>
                <Route exact path={siteConfig.prefixMe + `/update-private-compain/:compain_id`}
                       component={UpdateCompain}/>
                <Route exact path={siteConfig.prefixMe + `/engagement-info`} component={EngagementInfo}/>
                <Route exact path={`/notfound`} component={NotFound}/>
                <Route exact path={`/me`} component={MyProfile}/>
                <Route component={ProfilesAd}/>

            </Switch> : ''
        }
        {baseUrl === siteConfig.TalentUrl ?
            <Switch>
                <Route extact path={siteConfig.prefixMe + `/talent`} component={TalentInfo}/>
                <Route exact path={siteConfig.prefixMe + `/mes-collaborations`} component={Collaboration}/>
                <Route exact path={siteConfig.prefixMe + `/mes-collaborations/:compain_id`}
                       component={CollaborationDetails}/>
                <Route exact path={siteConfig.prefixMe + `/collaborations-public`} component={PublicCollaboration}/>
                <Route exact path={siteConfig.prefixMe + `/public-collaborations/:compain_id`}
                       component={PublicCollaborationDetails}/>
                <Route exact path={`/me`} component={TalentProfile}/>
                <Route exact path={`/notfound`} component={NotFound}/>
                <Route component={Profiles}/>
            </Switch> : ''
        }
        {baseUrl === siteConfig.AdminUrl ?
            <Switch>
                <Route extact path={siteConfig.prefixMe + `/talent`} component={TalentInfo}/>
                <Route exact path={siteConfig.prefixMe + `/public-compains`} component={Collaboration}/>
                <Route exact path={siteConfig.prefixMe + `/all-compains`} component={AdminCompainManager}/>
                <Route exact path={siteConfig.prefixMe + `/all-talents`} component={TalentsManager}/>
                <Route exact path={siteConfig.prefixMe + `/talents-scrapping`} component={TalentsScrapping}/>
                <Route exact path={siteConfig.prefixMe + `/all-advertisers`} component={AdvertisersManager}/>
                <Route exact path={siteConfig.prefixMe + `/detail-compain/:compain_id`} component={DetailCompain}/>
                <Route exact path={siteConfig.prefixMe + `/engagement-calculator`} component={EngagementInfo}/>
                <Route exact path={siteConfig.prefixMe + `/public-compains/:compain_id`}
                       component={CollaborationDetails}/>
                <Route exact path={siteConfig.prefixMe + `/private-compains-public`} component={PublicCollaboration}/>
                <Route exact path={siteConfig.prefixMe + `/public-compains/:compain_id`}
                       component={CollaborationDetails}/>
                <Route exact path={siteConfig.prefixMe + `/add-public-compain`} component={AddPublicCompain}/>
                <Route exact path={siteConfig.prefixMe + `/add-private-compain`} component={AddPrivateCompain}/>
                <Route exact path={siteConfig.prefixMe + `/update-compain/:compain_id`} component={UpdateCompainAdmin}/>
                <Route exact path={siteConfig.prefixMe + `/all_invitations`} component={Invitations}/>
                <Route exact path={siteConfig.prefixMe + `/settings`} component={Settings}/>
                <Route exact path={siteConfig.prefixMe + `/dashboard`} component={Dashboard}/>
                <Route exact path={siteConfig.prefixMe + `/all-agencies`} component={AgenciesManager}/>
                <Route exact path={`/me`} component={AdminCompainManager}/>
                <Route exact path={`/notfound`} component={NotFound}/>
                <Route component={Profiles}/>
            </Switch> : ''
        }
        {baseUrl === siteConfig.AgencyUrl ?
            <Switch>
                <Route exact path={siteConfig.prefixMe + `/advanced-search`} component={AdvancedSearch}/>
                <Route exact path={siteConfig.prefixMe + `/engagement-calculator`} component={EngagementInfoAgency}/>
                <Route exact path={`/me`} component={AdvancedSearch}/>
                <Route exact path={`/notfound`} component={NotFound}/>
                <Route component={Profiles}/>
            </Switch> : ''
        }
    </div>
);

export default App;
