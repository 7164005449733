import React, {useEffect} from "react";
import {Card, Divider, message, Popconfirm, Table} from "antd";
import businessSectorsApiService from "../../../../util/services/BusinessSectorsApiService";
import Pagination from "@material-ui/lab/Pagination";
import AddBusinessSector from "./add-business-sector";
import UpdateBusinessSector from "./update-busiess-sector";

const BusinessSectors = () => {
    const [businessSector, setBusinessSector] = React.useState([]);
    const [initApp, setInitApp] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [showDrawerAdd, setShowDrawerAdd] = React.useState(false);
    const [showDrawerUpdate, setShowDrawerUpdate] = React.useState(false);
    const [business_sector_id, setBusiness_sector_id] = React.useState(false);

    useEffect(() => {
        getAllBusinessSectors(page)
    }, [initApp]);

    const getAllBusinessSectors = (current_page) => {
        businessSectorsApiService.find({
            limit: 10,
            page: current_page
        }).then(res => {
            setBusinessSector(res.data.data || []);
            setPages(res.data.attributes.pages);
            setOffset(res.data.attributes.offset);
            setCount(res.data.attributes.count)
        });
    };

    const onChangePage = (event, newPage) => {
        getAllBusinessSectors(newPage)
    };

    const columns = [
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Code',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
      <span className="gx-link" onClick={() => EnableDrawerUpdateBusinessSector(record)}>Update</span>
      <Divider type="vertical"/>
   <Popconfirm title="Are you sure delete this business sector?" onConfirm={() => confirm(record)}
               onCancel={cancel} okText="Yes"
               cancelText="No">
      <span className="gx-link">Delete</span>
            </Popconfirm>      <Divider type="vertical"/>
    </span>
            ),
        }
    ];

    function confirm(data) {
        businessSectorsApiService.delete({id: data.business_sector_id}).then(res => {
            message.success('Business sector deleted wth success');
            getAllBusinessSectors(1);
        })
    }

    function cancel(e) {
    }

    const EnableDrawerAdd = () => {
        setShowDrawerAdd(true)
    }
    const disableDrawerAdd = (event) => {
        setShowDrawerAdd(event);
        getAllBusinessSectors(1)
    };

    const disableDrawerUpdate = (event) => {
        setShowDrawerUpdate(event);
        getAllBusinessSectors(1)
    };

    const EnableDrawerUpdateBusinessSector = (data) => {
        setBusiness_sector_id(data.business_sector_id)
        setShowDrawerUpdate(true)
    };

    return (
        <Card>
            <div className={"gx-mb-3"} style={{textAlign: "right"}}>
                <button className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-text-uppercase "
                        onClick={EnableDrawerAdd}>
                    Add
                </button>
            </div>
            <Table className="gx-table-responsive" columns={columns} dataSource={businessSector} pagination={false}/>
            {showDrawerAdd ?
                <AddBusinessSector enable_add={showDrawerAdd} disableDrawerAdd={disableDrawerAdd}/> : ''
            }
            {showDrawerUpdate ?
                <UpdateBusinessSector enable_update={showDrawerUpdate} disableDrawerUpdate={disableDrawerUpdate}
                                      business_sector_id={business_sector_id}/> : ''
            }
            <Pagination style={{float: "right"}} count={pages} color="primary" onChange={onChangePage}/>
        </Card>
    );
};

export default BusinessSectors;
