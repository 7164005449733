import React, {useEffect} from "react";
import {Col, Input, message, Popconfirm, Row} from "antd";
import siteConfig from "../../../config/site.config";
import {useIntl} from "react-intl";
import Pagination from "@material-ui/lab/Pagination";
import Widget from "../../../components/Widget";
import talentApiService from "../../../util/services/TalentsApiService";
import Loader from "../../compain-manager/loader";

const Search = Input.Search;

const Invitations = (props) => {
    const [allInvitations, setAllInvitations] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [meta_key, setMeta_key] = React.useState('')

    const intl = useIntl();

    useEffect(() => {
        getAllInvitations(page)
    }, [true]);

    const getAllInvitations = (page) => {
        talentApiService
            .getAllInvitations()
            .then(res => {
                setIsLoading(false);
                setAllInvitations(res.data.data || null);
                // setPages(res.data.attributes.pages);
                // setOffset(res.data.attributes.offset);
                // setCount(res.data.attributes.count)
            });
    };

    // const onFilterTalentsByCode = (code) => {
    //     setMeta_key(code)
    //     if (code && code !== '' && code.toString().length > 3) {
    //         talentApiService
    //             .find({
    //                 limit: -1,
    //                 meta_key: code.toString(),
    //                 sortBy: 'created_at',
    //                 sortDir: 'DESC',
    //             })
    //             .then(res => {
    //                 setIsLoading(false);
    //                 setAllTalents(res.data.data || null);
    //                 setPages(res.data.attributes.pages);
    //                 setOffset(res.data.attributes.offset);
    //                 setCount(res.data.attributes.count)
    //             });
    //     } else {
    //         getAllTalents(1)
    //     }
    // };

    const onChangePage = (event, newPage) => {
        let current_page = newPage
        setPage(current_page)
        getAllInvitations(current_page)
    };

    function cancel(e) {
    }
    const AcceptInvitation = (data) => {
        console.log(data)
        let formData = {
            compain_id: data?.compain_info?.compain_id,
            talent_id: data?.talent_info?.influencer_id
        }
        talentApiService
            .acceptInvitation(formData).then(res => {
            if (res.data.success === true) {
                getAllInvitations(1)
            }
        })
    };

    const DeclineInvitation = (data) => {
        let formData = {
            compain_id: data?.compain_info?.compain_id,
            talent_id: data?.talent_info?.influencer_id
        }
        talentApiService
            .declineInvitation(formData).then(res => {
            if (res.data.success === true) {
                message.success('Invitation Declined With success');
                getAllInvitations(1)
            }
        })
    }

    return (
        <div>
            {!isLoading ?
                <>
                    <Search
                        className="gx-ml-3"
                        enterButton="Search" size="large"
                        placeholder="input talent name"
                        style={{width: 500}}
                    />
                    <Row>
                        {allInvitations.map((item_invitation, idx) => (
                            <Col key={idx} xl={6} md={8} sm={12} xs={24}>
                                <Widget>
                                    <div className="gx-slider">
                                        <div className="gx-slider-img">
                                            <img alt="example"
                                                 src={siteConfig.AppConfig.baseStyleImageUrl + item_invitation?.compain_info?.image_efile_id}
                                                 style={{maxHeight: 185}}/>
                                            <img className="gx-img-up" alt="example"
                                                 src={siteConfig.AppConfig.baseStyleImageUrl + item_invitation?.talent_info?.profile_efile_id}/>
                                        </div>
                                        <div className="gx-slider-content">
                                            <h4>{item_invitation?.compain_info?.compain_name}</h4>
                                            <p className="gx-text-grey">{item_invitation?.compain_info?.description}</p>
                                        </div>
                                        <div style={{marginLeft: '20%'}}>
                                            <Popconfirm title="Are you sure you want to participate to this compain?"
                                                        onConfirm={() => AcceptInvitation(item_invitation)}
                                                        onCancel={cancel} okText="Yes"
                                                        cancelText="No">
                                                <span
                                                    className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-mr-4 gx-text-uppercas">Accept</span>
                                            </Popconfirm>
                                            <Popconfirm title="Are you sure you want to decline this compain?"
                                                        onConfirm={() => DeclineInvitation(item_invitation)}
                                                        onCancel={cancel} okText="Yes"
                                                        cancelText="No">
                                                <span
                                                    className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-mr-4 gx-text-uppercas">Decline</span>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                </Widget>
                            </Col>

                        ))}
                    </Row>
                    <Pagination style={{float: "right"}} count={pages} color="primary" onChange={onChangePage}/>
                </> :
                <Loader/>
            }

        </div>
    );
};

export default Invitations;


