import React from "react";
import {Table} from "antd";
import Widget from "../../../components/Widget";
import siteConfig from "../../../config/site.config";
import {Link} from "react-router-dom";

const StatsByCampagne = ({campagne_stats}) => {
    const renderCell = (object, type, key) => {
        if (object) {
            return <div className="gx-flex-row gx-align-items-center">
                <Link to={siteConfig.prefixMe + "/detail-compain/" + object?.compain_id}>
            <img
                        src={siteConfig.AppConfig.baseStyleImageUrl + object?.image_efile_id}/>
                </Link>
            </div>
        }
    };
    const columns = [
        {
            title: 'Picture',
            dataIndex: 'image_efile_id',
            className:"gx-rounded-circle gx-size-80 gx-mr-2",
            render: (text, record) => renderCell(record, 'ImageCell', 'image_efile_id'),
        },
        {
            title: 'Campagne name',
            dataIndex: 'compain_name',
            render: (text, record) => {
                return <div className="gx-flex-row gx-align-items-center">
                    <Link to={siteConfig.prefixMe + "/detail-compain/" + record?.compain_id}>
                    <p className="gx-mb-0">{record.compain_name}</p>
                    </Link>
                </div>
            },
        },
        {
            title: 'Total visit',
            dataIndex: 'transfer',
            render: (text, record) => {
                return<Link to={siteConfig.prefixMe + "/detail-compain/" + record?.compain_id}>

                <span className="gx-text-grey">{record.total_visits}</span>
                </Link>
            },

        },
    ];

    return (
        <Widget
            title={
                <h2 className="h4 gx-text-capitalize gx-mb-0">
                    Top 10 campagne</h2>
            }>
            <div className="gx-table-responsive">
                <Table className="gx-table-no-bordered" columns={columns} dataSource={campagne_stats} pagination={false}
                       size="small"/>
            </div>
            <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-block gx-d-sm-none gx-mb-0 gx-mt-3">
                <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"/> Add New Account</p>
        </Widget>
    );
};

export default StatsByCampagne;
