import BaseApiService from "../BaseApiService";
import * as axios from "axios";
import {apiUrl} from "../../config/site.config";

class InvestigationApiService extends BaseApiService {
  constructor() {
    super('investigation_data');
  }
  getByUserId(user_id) {
    return axios.get(apiUrl + '/' + this.entity_name + '/getByUserId?user_id='+ user_id);
  };
}

const investigationApiService = new InvestigationApiService();

export default investigationApiService;
