import React from "react";
import Widget from "../../Widget";

const Biography = ({talent_info}) => {
    return (
        <Widget styleName="gx-card-profile">
            <div className="ant-card-head">
                <span className="ant-card-head-title gx-mb-2">Biography</span>
            </div>
            <p>{talent_info?.influencer_bio}</p>
        </Widget>
    )
}

export default Biography;
