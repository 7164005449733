import React, {useEffect} from "react";
import {Card, Divider, message, Popconfirm, Table} from "antd";
import Pagination from "@material-ui/lab/Pagination";
import '../../../../assets/scss/App.scss'
import BaseApiService from "../../../../util/BaseApiService";
import UpdateManager from "./update-manager";
import AddManager from "./add-manager";
import siteConfig from "../../../../config/site.config";

const baseApiService = new BaseApiService();

const Managers = () => {
    const [managers, setManagers] = React.useState([]);
    const [initApp, setInitApp] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pages, setPages] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [showDrawerAdd, setShowDrawerAdd] = React.useState(false);
    const [showDrawerUpdate, setShowDrawerUpdate] = React.useState(false);
    const [manager_id, setManager_id] = React.useState(false);
    const renderCell = (object, type, key) => {
        if (object) {
            return <img className="ant-avatar ant-avatar-circle ant-avatar-image gx-size-80"
                        src={siteConfig.AppConfig.baseStyleImageUrl + object}/>
        }
    };
    const columns = [
        {
            title: 'Picture',
            dataIndex: 'profile_efile_id',
            key: 'profile_efile_id',
            className: 'gx-size-100',
            render: object => renderCell(object, 'ImageCell', 'profile_image_id'),
        },
        {
            title: 'First name',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Last name',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'user_email',
            key: 'user_email',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
      <span className="gx-link" onClick={() => EnableDrawerUpdateCategory(record)}>Update</span>
      <Divider type="vertical"/>
      <Popconfirm title="Are you sure delete this manager?" onConfirm={() => confirm(record)}
                  onCancel={cancel} okText="Yes"
                  cancelText="No">
      <span className="gx-link">Delete</span>
            </Popconfirm>
      <Divider type="vertical"/>
    </span>
            ),
        }
    ];

    useEffect(() => {
        getAllManagers(page)
    }, [initApp]);

    const getAllManagers = (current_page) => {
        let filter = [];
        filter.push({
            operator: 'and',
            conditions: [
                {
                    field: 'profile_id',
                    operator: 'eq',
                    value: 1
                }
            ]
        });
        baseApiService.getAllManagers({
            limit: 10,
            page: current_page,
            filter: filter
        }).then(res => {
            setIsLoading(false);
            let data = res.data.data
            data.forEach((item, idx) => {
                item.key = idx
            })
            setManagers(data || []);
            setPages(res.data.attributes.pages);
            setOffset(res.data.attributes.offset);
            setCount(res.data.attributes.count)
        });
    };

    const onChangePage = (event, newPage) => {
        getAllManagers(newPage)
    };

    function confirm(data) {
        baseApiService.deleteUser({id: data.user_id}).then(res => {
            message.success('Manager deleted wth success');
            getAllManagers(1)
        })
    }

    function cancel(e) {
    }

    const EnableDrawerAdd = () => {
        setShowDrawerAdd(true)
    }
    const disableDrawerAdd = (event) => {
        setShowDrawerAdd(event);
        getAllManagers(1)
    };

    const disableDrawerUpdate = (event) => {
        setShowDrawerUpdate(event);
        getAllManagers(1)
    };

    const EnableDrawerUpdateCategory = (data) => {
        setManager_id(data.user_id)
        setShowDrawerUpdate(true)
    };

    return (
        <>
            {!isLoading ?
                <Card>
                    <div className={"gx-mb-3"} style={{textAlign: "right"}}>
                        <button className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-text-uppercase "
                                onClick={EnableDrawerAdd}>
                            Add
                        </button>
                    </div>
                    <Table className="gx-table-responsive" columns={columns} dataSource={managers}
                           pagination={false}/>
                    {showDrawerAdd ?
                        <AddManager enable_add={showDrawerAdd} disableDrawerAdd={disableDrawerAdd}/> : ''
                    }
                    {showDrawerUpdate ?
                        <UpdateManager enable_update={showDrawerUpdate} disableDrawerUpdate={disableDrawerUpdate}
                                       manager_id={manager_id}/> : ''
                    }
                    <Pagination style={{float: "right"}} count={pages} color="primary" onChange={onChangePage}/>
                </Card> :
                <div id="preloader-wrapper" style={{borderColor: '#ffffff'}}>
                    <div className="preloader-container">
                        <div className="cssload-loader">
                            <div className="cssload-inner cssload-one" style={{borderColor: '#fd67af'}}/>
                            <div className="cssload-inner cssload-two" style={{borderColor: '#9201fe'}}/>
                            <div className="cssload-inner cssload-three" style={{borderColor: '#49cfff'}}/>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Managers;
