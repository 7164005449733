import React, {useEffect, useState} from "react";
import Widget from "../../Widget/index";
import siteConfig from "../../../config/site.config";
import {message, Spin} from "antd";
import {useIntl} from "react-intl";
import {translateMsg} from "../../../util/IntlMessages";
import fbApiService from "../../../util/services/FBApiService";
import UpdatePicturesProfile from "../../../routes/profile-ad-info/my-profile/update-pictures";

const Photos = ({photoList, talent, picturesIds, updateMe}) => {
    const [pictures, setPictures] = useState(photoList || []);
    const [showManagingPictures, setShowManagingPictures] = useState(false);
    const [loading, setLoading] = useState(true);
    const intl = useIntl();

    useEffect(() => {
        setPictures(photoList);
        setLoading(false);
    }, [photoList]);

    const disableDrawerManagingPictures = (event) => {
        setShowManagingPictures(event);
        updateMe();
    };


    const importMediasFromIG = () => {
        setLoading(true);
        fbApiService.importMediasFromIG().then(response => {
            setPictures(response.data.data || []);
            message.info("Medias imported with success");
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            if (err.response.data && err.response.data.messages) {
                err.response.data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                })
            }
        });
    }

    return (
        <Widget title="Photos" styleName="gx-card-profile-sm">
            <div className="gx-pt-2">
                <ul className="gx-gallery-list">
                    {pictures ?
                        pictures.map((efile_id, index) =>
                            <li key={efile_id}>
                                <img alt="..." src={siteConfig.AppConfig.baseStyleImageUrl + efile_id}/>
                            </li>
                        ) : ''
                    }
                </ul>
                {
                    pictures && pictures.length ?
                        <div style={{textAlign: "center"}}>
                            <button disabled={loading} type={"button"} className="ant-btn ant-btn-success"
                                    style={{
                                        backgroundColor: '#002447',
                                        color: '#ffffff',
                                        float: 'right'
                                    }}
                                    onClick={() => {
                                        setShowManagingPictures(true)
                                    }}>Manage my pictures
                            </button>
                            {
                                showManagingPictures ?
                                    <UpdatePicturesProfile picturesList={picturesIds}
                                                           enable_update={showManagingPictures}
                                                           disableDrawerManagingPictures={disableDrawerManagingPictures}/> : ''
                            }
                        </div>
                        : ''
                }
                {!pictures || !pictures.length ?
                    <div style={{textAlign: "center"}}>
                        <button disabled={loading} type={"button"} className="ant-btn ant-btn-success" onClick={() => {
                            importMediasFromIG();
                        }}>Import from my Instagram
                        </button>
                    </div>
                    : ''
                }
                {loading ?
                    <div style={{textAlign: "center"}}><Spin tip="Loading..."/></div>
                    : ''
                }
            </div>
        </Widget>
    )
}
export default Photos
