import BaseApiService from "../BaseApiService";
import * as axios from "axios";
import {apiUrl, fbApiUrl} from "../../config/site.config";

class FBApiService extends BaseApiService {

  accessToken = '';
  businessAccounts = [];
  IGAccounts = [];
  userFbData = [];

  constructor() {
    super('fb_account');
  }

  setToken(token) {
    this.accessToken = token;
  }

  setUserFbData(user_fb_data) {
    this.userFbData = user_fb_data;
  }

  setBusinessAccounts(data) {
    this.businessAccounts = data;
  }

  setIGAccounts(data) {
    this.IGAccounts = data;
  }

  requestBusinessAccounts() {
    const fields = 'id,name,picture,access_token,instagram_business_account';
    return axios.get(fbApiUrl + `/me/accounts?fields=${fields}&access_token=` + this.accessToken);
  }

  requestIGAccounts() {
    const fields = 'id,follow_count,followed_by_count,is_private,is_published,media_count,profile_pic,username';
    //return axios.get(fbApiUrl + `/me/instagram_accounts?fields=${encodeURI(fields)}&access_token=` + this.accessToken);
    return axios.get(fbApiUrl + `/me/instagram_accounts/?access_token=` + this.accessToken);
  }

  getIgAccounts(businessAccount) {
    const fields = 'follows_count,followers_count,id,ig_id,media_count,name,profile_picture_url,username,website';
    const url = fbApiUrl + `/${businessAccount.instagram_business_account.id}?fields=${fields}&access_token=${businessAccount.access_token}`;
    return axios.get(url);
  }

  registerIGAccount(profile_type, selected_account) {
    return axios.post(apiUrl + '/' + profile_type + '/registerIGAccount', {
      selected_account: selected_account,
      business_accounts: this.businessAccounts,
      access_token: this.accessToken,
      user_fb_data: this.userFbData,
    });
  }

  loginIGAccount(selected_account, profile_type) {
    return axios.post(apiUrl + '/fb_api/loginIGAccount', {
      selected_account: selected_account,
      business_accounts: this.businessAccounts,
      access_token: this.accessToken,
      user_fb_data: this.userFbData,
      profile_type: profile_type,
    });
  }

  validateIGAccount(profile_type, selected_account) {
    /*if (profile_type.toLowerCase() === 'talent') {
      profile_type = 'influencer';
    }
    if (profile_type.toLowerCase() === 'ad') {
      profile_type = 'advertiser';
    }*/
    return this.setHeaderToken().post(apiUrl + '/fb_api/validateIGAccount', {
      selected_account: selected_account,
      business_accounts: this.businessAccounts,
      access_token: this.accessToken,
      user_fb_data: this.userFbData,
    });
  }

  validateIGWithComment() {
    return this.setHeaderToken().post(apiUrl + '/fb_api/validateIGWithComment');
  }

  importMediasFromIG() {
    return this.setHeaderToken().post(apiUrl + '/fb_api/importMediasFromIG');
  }

}

const fbApiService = new FBApiService();

export default fbApiService;
