import React from "react";
import {Button, Col, message, Popconfirm, Row} from "antd";
import IntlMessages from "../../util/IntlMessages";
import siteConfig from "../../config/site.config";
import {Link} from "react-router-dom";
import compainApiService from "../../util/services/CompainApiService";
import Moment from 'react-moment';

const CompainItem = ({compain, grid, updateMe}) => {
    function confirm(compain) {
        compainApiService.deleteCompain(compain.compain_id).then(res => {
            message.success('Campaign Deleted With success');
            updateMe()
        })
    }

    function cancel(e) {
    }

    return (
        <div className={`gx-product-item  ${grid ? 'gx-product-vertical' : 'gx-product-horizontal'}`}>
            <div className="gx-product-image">
                <div className="gx-grid-thumb-equal">
          <span className="gx-link gx-grid-thumb-cover">
            <Link to={siteConfig.prefixMe + "/detail-compain/" + compain?.compain_id}>
                          <img alt="Remy Sharp" src={siteConfig.AppConfig.baseStyleImageUrl + compain?.image_efile_id}/>

            </Link>
          </span>
                </div>
            </div>

            <div className="gx-product-body">
                <Row>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <h3 className="gx-product-title">{compain?.compain_name}</h3>
                    </Col>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <h3 className="gx-product-title" style={{float: 'right'}}><Moment
                            format="YYYY/MM/DD">{compain?.start_date_at}</Moment></h3>
                    </Col>
                </Row>
                <p style={{marginTop: '10px'}}>{compain?.description}</p>
            </div>

            <div className="gx-product-footer">
                <Button type="primary"><IntlMessages id="compain.readMore"/><Link
                    to={siteConfig.prefixMe + "/detail-compain/" + compain?.compain_id}/></Button>
                <Popconfirm title="Are you sure delete this campaign?" onConfirm={() => confirm(compain)}
                            onCancel={cancel} okText="Yes"
                            cancelText="No">
                    <Button type="secondary"><IntlMessages id="compain.delete"/></Button>
                </Popconfirm>
                {siteConfig.IsAdmin ?
                    <span className="ant-badge ant-badge-not-a-wrapper" style={{float: "right"}}>
                    {compain && compain.category === 'public' ?
                        <sup data-show="true" className="ant-scroll-number ant-badge-count ant-badge-multiple-words"
                             title="HTML" style={{backgroundColor: 'teal'}}>public</sup>
                        :
                        <sup data-show="true" className="ant-scroll-number ant-badge-count ant-badge-multiple-words"
                             title="HTML">private</sup>
                    }
                </span> :
                    <span className="ant-badge ant-badge-not-a-wrapper" style={{float: "right"}}>
                    {compain && compain.status === 'started' ?
                        <sup data-show="true" className="ant-scroll-number ant-badge-count ant-badge-multiple-words"
                             title={compain.status} style={{backgroundColor: 'teal'}}>Started</sup>
                        :
                        <span>
                            {compain && compain.status === 'not_started' ?
                                <sup data-show="true"
                                     className="ant-scroll-number ant-badge-count ant-badge-multiple-words"
                                     title={compain.status} style={{backgroundColor: 'yellow', color: 'black'}}>Not started</sup>
                                :
                                <sup data-show="true"
                                     className="ant-scroll-number ant-badge-count ant-badge-multiple-words"
                                     title={compain.status}>Finie</sup>
                            }
                        </span>
                    }

                </span>
                }
            </div>
        </div>
    )
};

export default CompainItem;

