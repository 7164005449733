import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

const ProfilesAd = ({match}) => (
  <Switch>
    <Route path={`/:username`} component={asyncComponent(() => import('./Profile'))}/>
  </Switch>
);

export default ProfilesAd;
