import React, {useEffect} from "react";
import talentApiService from "../../../util/services/TalentsApiService";
import siteConfig from "../../../config/site.config";
import {Button, Card, Col, DatePicker, Form, Input, message, Modal, Row, Select, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import moment from "moment";
import {post} from "axios";
import compainApiService from "../../../util/services/CompainApiService";
import {useHistory} from 'react-router-dom';
import advertiserApiService from "../../../util/services/AdvertisersApiService";

const AddPublicCompain = (props) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const {TextArea} = Input;
    const [Talents, setTalents] = React.useState([]);
    const [TalentsInvited, setTalentsInvited] = React.useState([]);
    const [advertiser_id, setAdvertiser_id] = React.useState();
    const [compain_efile_id, setCompain_efile_id] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewPicture, setPreviewPicture] = React.useState('');
    const [advertisers, setAdvertisers] = React.useState([]);
    const [initApp, setInitApp] = React.useState(true);

    useEffect(() => {
        let filter = [];
        filter.push({
            operator: 'and',
            conditions: [
                {
                    field: 'is_valid',
                    operator: 'eq',
                    value: 'Y'
                }
            ]
        });
        talentApiService
            .find({
                limit: -1,
                filter: filter
            })
            .then(res => {
                setIsLoading(false);
                setTalents(res.data.data || null);
            });
    }, [advertiser_id]);

    useEffect(() => {
        advertiserApiService.find({limit: -1}).then(res => {
            console.log(res)
            setAdvertisers(res.data.data || []);
        });
    }, [initApp]);

    const onFinish = values => {
        const ad_id = values.advertiser_id? values.advertiser_id : null
        const influencers_ids = []
        if (TalentsInvited && TalentsInvited.length !== 0) {
            TalentsInvited.forEach(item_talent => {
                influencers_ids.push(item_talent.influencer_id)
            })
        }
        let FormData = values
        FormData.start_date_at = moment(values.start_date_at).format('YYYY-MM-DD')
        FormData.end_date_at = moment(values.end_date_at).format('YYYY-MM-DD')
        FormData.advertiser_id = ad_id
        FormData.category = 'public'
        FormData.influencers_ids = influencers_ids
        FormData.image_efile_id = compain_efile_id
        compainApiService.addCompain(FormData).then(res => {
            if (res.data.success === true) {
                message.success('Compaign Created With success');
                window.location.href ='all-compains'
            } else {
                message.error(res.data.message);
            }
        })
    };

    const handleUploadClick = event => {
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            return post(siteConfig.AppConfig.fileUploadUrl, formData, {
                headers: {
                    'Content-Type': `multipart/form-data;`,
                },
                timeout: 30000,
            }).then(res => {
                setCompain_efile_id(res.data.data)
            })
        }
    };
    const handleCancel = () => {
        setPreviewVisible(false)
    }
    const handlePreview = (file) => {
        setPreviewPicture(file.url || file.thumbUrl)
        setPreviewVisible(true)
    };
    const compainImage = [{
        uid: -1,
        name: 'compain image',
        status: 'done',
        url: siteConfig.AppConfig.baseStyleImageUrl + compain_efile_id,
    }]

    const handleChange = () => {
        setCompain_efile_id(null)
    }
    return (
        <div>
            <Form
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
            >
                <Form.Item style={{margin: "0 0 0 95%"}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            name="compain_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign Name!',
                                },
                            ]}
                        >
                            <Input placeholder="Compaign Name"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign Description!',
                                },
                            ]}
                        >
                            <TextArea rows={4} placeholder="Description"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name="start_date_at"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign Start Date!',
                                },
                            ]}
                        >
                            <DatePicker className="gx-mb-3 gx-w-100" placeholder="Start date"/>
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24} className={"ml-2"}>
                        <Form.Item
                            name="end_date_at"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign End Date!',
                                },
                            ]}
                        >
                            <DatePicker className="gx-mb-3 gx-w-100" placeholder="End date"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name="url"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign Url!',
                                },
                            ]}
                        >
                            <Input placeholder="Compaign Url"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            name="offre"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Compaign offre!',
                                },
                            ]}
                        >
                            <TextArea rows={4} placeholder="Offre"/>
                        </Form.Item>
                    </Col>
                    <Row>
                        <Col lg={8} md={8} sm={12} xs={24} style={{display: 'block'}}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name='advertiser_id'
                                >
                                    <Select
                                        showSearch
                                        style={{width: 400}}
                                        placeholder={"Select a advertiser"}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {advertisers?.map(option => {
                                            return <option
                                                value={option.advertiser_id}>{option.advertiser_name}</option>
                                        })}

                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <Col lg={3} md={6} sm={24} xs={24} className={"ml-2"}>
                        <Form.Item
                            label={"Compaign Image"}>
                            <div className={"group-file-upload"}>
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleUploadClick}
                                />
                                <Button icon={<UploadOutlined/>} onClick={() => {
                                    document.getElementById('contained-button-file').click()
                                }}>Click to upload</Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                {compain_efile_id ?
                    <Row>
                        <Card title="Compain picture" className="gx-card clearfix">
                            <Upload
                                listType="picture-card"
                                fileList={compainImage}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                            </Upload>
                            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                <img alt="Compain picture" style={{width: '100%'}} src={previewPicture}/>
                            </Modal>
                        </Card>
                    </Row> : ''
                }
            </Form>
        </div>
    );
};

export default AddPublicCompain;


