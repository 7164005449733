
const engagement_rate = [
  {
    value: [0.5 ,1],
    label: '0.5 - 1'
  },
  {
    value: [1, 5 ],
    label: '1 - 5'
  },

  {
    value: [5 ,10],
    label: '5 - 10',
  },
  {
    value: [10 ,15],
    label: '10 - 15'
  },
  {
    value: [15, 100],
    label: '15 et plus'
  }
];

export const tawaEngagement_rate = engagement_rate;
