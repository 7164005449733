import React, {Fragment, useEffect} from "react";
import compainApiService from "../../../util/services/CompainApiService";
import StatsByCampagne from "./statsByCampagne";
import {Row, Col, Card, DatePicker, Popover} from "antd";
import IconWithTextCard from "../../../components/Metrics/IconWithTextCard";
import moment from "moment";
import EcommerceStatus from "../../../components/Metrics/EcommerceStatus";
import BaseApiService from "../../../util/BaseApiService";
const RangePicker = DatePicker.RangePicker;
const baseApiService = new BaseApiService();

const Dashboard = (props) => {
    const [statsCampaigns, setStatsCampaigns] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        getStatsCampaigns(1);
        getStats();
    }, [true]);

    const getStatsCampaigns = (page) => {
        compainApiService
            .statsCampaign()
            .then(res => {
                const data = res.data.data;
                data.forEach((item, idx) => {
                    item.key = idx
                })
                setStatsCampaigns(data);
            });
    };
    const getStats = (params) => {
        baseApiService.stats(params)
            .then(res => {
                const data = res.data.data;
                setStats(data);
            });
    };

    const onChange = (dates, dateStrings) => {
        let params = {
            start_date:  dateStrings[0],
            end_date: dateStrings[1]
        }
        getStats(params)

    };
    const resetDate = () => {
    let params = null
        getStats(params)
    }

    return (
        <div>
            <Row>
                <Col xl={22} lg={22} md={22} sm={22} xs={22} className="gx-col-full">
                <RangePicker className="gx-mb-3 gx-w-100"
                             ranges={{Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')]}}
                             onChange={onChange}
                />
                </Col>
                <div className={"gx-mb-3"} style={{textAlign: "right"}}>
                    <button className="ant-btn gx-btn gx-btn-orange gx-mb-0 gx-text-uppercase" onClick={resetDate}>
                            Reset
                    </button>
                </div>
            </Row>
            <Row>
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                    <IconWithTextCard icon="orders" iconColor="geekblue" title={stats?.public_campaigns?.length} subTitle="public campagnes"/>
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                    <IconWithTextCard icon="revenue-new" iconColor="primary" title={stats?.private_campaigns?.length} subTitle="private campagnes"/>
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                    <IconWithTextCard icon="visits" iconColor="geekblue" title={stats?.total_finished_campaigns?.length} subTitle="finished campagnes"/>
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                    <IconWithTextCard icon="profile2" iconColor="geekblue" title={stats?.total_valid_talents?.length} subTitle="valid talents"/>
                </Col>
            </Row>
            <div  style={{textAlign: "center"}}>
                <EcommerceStatus color="orange" icon="dasbhoard" title={stats?.total_visits} colorTitle="geekblue"
                                 subTitle="Total visit" colorSubTitle="geekblue"/>
            </div>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <StatsByCampagne campagne_stats={statsCampaigns}/>
            </Col>
        </div>
    );
};

export default Dashboard;


