import React, {useState} from "react";
import {Card, Tabs} from "antd";
import Categories from "./categories/categories";
import BusinessSectors from "./business-sectors/business-sectors";
import Managers from "./managers/managers";

const TabPane = Tabs.TabPane;

const Settings = () => {

    const [mode, setMode] = useState('top');
    return (
        <Card className="gx-card">
            <Tabs
                defaultActiveKey="1"
                tabPosition={mode}
            >
                <TabPane tab="Managers" key="1">
                    <Managers/>
                </TabPane>
                <TabPane tab="Categories" key="2">
                    <Categories/>
                </TabPane>
                <TabPane tab="Business sectors" key="3">
                    <BusinessSectors/>
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Settings;
