import React, {useEffect} from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import {Button, Card, Checkbox, Form, Input, Modal, Radio, Upload} from "antd";
import {makeStyles} from "@material-ui/core/styles";
import BaseApiService from "../../../../../util/BaseApiService";
import IntlMessages, {translateMsg} from "../../../../../util/IntlMessages";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import {tawaCities} from "../../../../../util/cities";
import MenuItem from "@material-ui/core/MenuItem";
import categoriesApiService from "../../../../../util/services/CategoriesApiService";
import talentApiService from "../../../../../util/services/TalentsApiService";
import {message} from "antd/lib/index";
import {useIntl} from "react-intl";
import {post} from "axios";
import siteConfig from "../../../../../config/site.config";
import {UploadOutlined} from "@ant-design/icons";

const baseApiService = new BaseApiService();
const FormItem = Form.Item;

const UpdateTalentProfile = ({enable_update, disableDrawerUpdate, talent_id}) => {
    const [talent, setTalent] = React.useState({});
    const [form] = Form.useForm();
    const [selectedVille, setSelectedVille] = React.useState('');
    const [selectedGender, setSelectedGender] = React.useState(null);
    const [age, setAge] = React.useState('');
    const [categories, setCategories] = React.useState([]);
    const [selectCategories, setSelectedCategories] = React.useState([]);
    const [initApp, setInitApp] = React.useState(true);
    const [updateIgUsername, setUpdateIgUsername] = React.useState(false);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewPicture, setPreviewPicture] = React.useState('');
    const [talent_efile_id, setTalent_efile_id] = React.useState();
    const intl = useIntl();

    const [state, setState] = React.useState({
        right: enable_update,
    });
    const useStyles = makeStyles({
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    });

    const classes = useStyles();

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const getTalentInfo = () => {
        talentApiService.findById(talent_id)
            .then(res => {
                const talentData = res.data.data;
                console.log(talentData)
                setTalent(res.data.data || null);
                setTalent_efile_id(talentData?.profile_efile_id);
                setSelectedVille(talentData?.ville);
                setSelectedGender(talentData?.gender);
                setAge(talentData?.age_intervale);
                setSelectedCategories(talentData?.categories)
                if (talentData && talentData.is_valid === 'Y') {
                    setUpdateIgUsername(true)
                }
            });
    };

    useEffect(() => {
        categoriesApiService.find({limit: -1}).then(res => {
            setCategories(res.data.data || []);
        });
    }, [initApp]);

    const handleChangeVille = (event) => {
        event.persist()
        setSelectedVille(event.target.value)
        form.setFieldsValue({
            ville: event.target.value || null
        })
    };

    const handleUploadClick = event => {
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            return post(siteConfig.AppConfig.fileUploadUrl, formData, {
                headers: {
                    'Content-Type': `multipart/form-data;`,
                },
                timeout: 30000,
            }).then(res => {
                setTalent_efile_id(res.data.data)
            })
        }
    };
    const handleCancel = () => {
        setPreviewVisible(false)
    }
    const handlePreview = (file) => {
        setPreviewPicture(file.url || file.thumbUrl)
        setPreviewVisible(true)
    };
    const talentPicture = [{
        uid: -1,
        name: 'talent picture',
        status: 'done',
        url: siteConfig.AppConfig.baseStyleImageUrl + talent_efile_id,
    }]

    const handleChange = () => {
        talent_efile_id(null)
    }


    useEffect(() => {
        getTalentInfo()
    }, []);

    useEffect(() => {
        let formData = {...talent, ...{}};
        form.setFieldsValue(formData);
        form.setFieldsValue({
            phone: formData?.phone,
            ville: formData?.ville,
            gender: formData?.gender,
            age: formData?.age_intervale,
            categories: formData?.categories,
            ig_username: formData?.ig_username,
        });
    }, [talent]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            disableDrawerUpdate(false)
            return;
        }
        setState({...state, [anchor]: open});
        disableDrawerUpdate(false)

    };

    const onFinish = values => {
        const formProfile = values
        formProfile.categories = selectCategories;
        formProfile.ville = selectedVille;
        formProfile.age = age;
        formProfile.influencer_id = talent_id;
        formProfile.profile_efile_id = talent_efile_id;
        talentApiService.updateTalentInfo(formProfile).then(res => {
            message.success('Account updated with success', 3)
            disableDrawerUpdate(false)

        }).catch(err => {
            const {data} = err.response;
            if (data && data.messages) {
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    };

    const handleChangeAge = (event) => {
        setAge(event.target.value);
        form.setFieldsValue({
            age: event.target.value || null
        })
    };

    const handleChangeCategories = (selected_categories) => {
        setSelectedCategories(selected_categories);

    };

    const FormProfile = (anchor) => {
        return (<div
                className={clsx(classes.list, {
                    [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                })}
                role="presentation"
            >
                <Card className="gx-card" title="Update Profile">
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="update"
                        onFinish={onFinish}
                        initialValues={{
                            prefix: '216',
                        }}
                        scrollToFirstError
                        autoComplete={"off"}
                    >
                        <Form.Item
                            name="username"
                            label="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your user name!',
                                },
                            ]}

                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="first_name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First name!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label="Family Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Last name!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="contact_tel"
                            label="Phone Number"
                            rules={[{required: false, message: 'Please input your phone number!'}]}
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        {/*<Form.Item*/}
                        {/*    name="user_email"*/}
                        {/*    label="Email"*/}
                        {/*    disabled={true}*/}
                        {/*>*/}
                        {/*    <Input style={{width: '100%'}}/>*/}
                        {/*</Form.Item>*/}
                        <Form.Item
                            name="ig_username"
                            label="Instagram user name"
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <FormItem
                            name="ville"
                            label="Ville"
                            rules={[{required: true, message: 'Please select your city!'}]}
                        >
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={selectedVille}
                                    onChange={handleChangeVille}
                                >
                                    {tawaCities.map(option => {
                                        return <MenuItem value={option.value}
                                                         key={option.value}>{option.label}</MenuItem>
                                    })}
                                </Select>

                            </FormControl>
                        </FormItem>
                        <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[{required: true, message: 'Please select your gender!'}]}
                        >
                            <div className={"mb-3"}>
                                <Radio.Group onChange={(e) => setSelectedGender(e.target.value)} value={selectedGender}>
                                    <Radio value={"M"}><IntlMessages id="homme"/></Radio>
                                    <Radio value={"F"}><IntlMessages id="femme"/></Radio>
                                </Radio.Group>
                            </div>
                        </Form.Item>

                        <Form.Item name="age"
                                   label="Age"
                                   rules={[{required: true, message: 'Please select your age!'}]}
                        >
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="age"
                                    value={age}
                                    onChange={handleChangeAge}
                                >
                                    <MenuItem value={'18-24'}>18-24</MenuItem>
                                    <MenuItem value={'25-34'}>25-34</MenuItem>
                                    <MenuItem value={'35-44'}>35-44</MenuItem>
                                    <MenuItem value={'45-60'}>45-60</MenuItem>
                                </Select>
                            </FormControl>
                        </Form.Item>

                        <Form.Item
                            name="categories"
                            label="Categories"
                            rules={[{required: true, message: 'Please select your Categories!'}]}
                        >
                            <Checkbox.Group onChange={handleChangeCategories}
                                            defaultValue={categories}>
                                {categories.map(option => {
                                    return <Checkbox className={"gx-ml-0 gx-mr-1 gx-mb-1"} key={option.value}
                                                     value={option.category_id}>{option.label}</Checkbox>
                                })}
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item
                            name="passwordhash"
                            label="Password"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your password!',
                                },
                            ]}
                            autoComplete={"new-password"}
                            hasFeedback
                        >
                            <Input.Password autoComplete={"new-password"} />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['passwordhash']}
                            hasFeedback
                            rules={[
                                {
                                    required: false,
                                    message: 'Please confirm your password!',
                                },
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if ((!value && !getFieldValue('passwordhash')) || getFieldValue('passwordhash') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password autoComplete={"new-password"} />
                        </Form.Item>
                        <Form.Item
                            label={"Talent picture"}>
                            <div className={"group-file-upload"}>
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleUploadClick}
                                />
                                <Button icon={<UploadOutlined/>} onClick={() => {
                                    document.getElementById('contained-button-file').click()
                                }}>Click to upload</Button>
                            </div>
                        </Form.Item>
                        {talent_efile_id ?
                            <Card title="Talent picture" className="gx-card clearfix">
                                <Upload
                                    listType="picture-card"
                                    fileList={talentPicture}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                >
                                </Upload>
                                <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                    <img alt="Talent picture" style={{width: '100%'}} src={previewPicture}/>
                                </Modal>
                            </Card> : ''
                        }

                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                            <Button type="secondary" onClick={() => disableDrawerUpdate(false)}><IntlMessages
                                id="drawer.cancel"/></Button>
                        </Form.Item>
                    </Form>
                </Card>

            </div>
        );
    };

    return (
        <Drawer anchor={'right'} open={state['right']}
                onClose={toggleDrawer('right', false)}>
            {FormProfile('right')}
        </Drawer>
    )
};

export default UpdateTalentProfile;
