import React, {useEffect} from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import {Button, Card, Checkbox, Form, Input, Modal, Upload} from "antd";
import {makeStyles} from "@material-ui/core/styles";
import BaseApiService from "../../../../../util/BaseApiService";
import IntlMessages, {translateMsg} from "../../../../../util/IntlMessages";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import {tawaCities} from "../../../../../util/cities";
import MenuItem from "@material-ui/core/MenuItem";
import categoriesApiService from "../../../../../util/services/CategoriesApiService";
import {message} from "antd/lib/index";
import {useIntl} from "react-intl";
import advertiserApiService from "../../../../../util/services/AdvertisersApiService";
import businessSectorsApiService from "../../../../../util/services/BusinessSectorsApiService";
import siteConfig from "../../../../../config/site.config";
import {UploadOutlined} from "@ant-design/icons";
import {post} from "axios";

const baseApiService = new BaseApiService();
const FormItem = Form.Item;
const dateFormat = 'YYYY/MM/DD';


const UpdateProfileAdvertiser = ({enable_update, disableDrawerUpdate, advertiser_id}) => {
    const [advertiser, setAdvertiser] = React.useState({});
    const [form] = Form.useForm();
    const [selectedVille, setSelectedVille] = React.useState([]);
    const [selectedGender, setSelectedGender] = React.useState(null);
    const [age, setAge] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [selectCategories, setSelectedCategories] = React.useState({});
    const [businessSector, setBusinessSector] = React.useState([]);
    const [selectedCible, setselectedCible] = React.useState([]);
    const [selectedBusinessSector, setSelectedBusinessSector] = React.useState(null);
    const [updateIgUsername, setUpdateIgUsername] = React.useState(false)
    const [initApp, setInitApp] = React.useState(true);
    const intl = useIntl();
    const [stateDrawer, setStateDrawer] = React.useState(enable_update);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewPicture, setPreviewPicture] = React.useState('');
    const [ad_efile_id, setAd_efile_id] = React.useState();
    const useStyles = makeStyles({
        list: {
            width: 1000,
            maxWidth: '100%',
        },
        fullList: {
            width: 'auto',
        },
    });
    const classes = useStyles();
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    const getAdInfo = () => {
        let filter = [];
        filter.push({
            operator: 'and',
            conditions: [
                {
                    field: 'advertiser_id',
                    operator: 'eq',
                    value: advertiser_id
                }
            ]
        });
        let params = {
            filter: filter
        }
        advertiserApiService.findById(advertiser_id)
            .then(res => {
                const adData = res.data.data;
                setAdvertiser(res.data.data || null);
                setAd_efile_id(adData?.profile_efile_id)
                setSelectedVille(adData?.ville || []);
                setSelectedGender(adData?.gender);
                setAge(adData?.ages || []);
                setSelectedBusinessSector(adData.business_sector_id);
                setselectedCible(adData?.profile?.cible);
                setSelectedCategories(adData?.profile?.categories)
                if (adData && adData.is_valid === 'Y') {
                    setUpdateIgUsername(true)
                }
            });
    };

    useEffect(() => {
        categoriesApiService.find({limit: -1}).then(res => {
            setCategories(res.data.data || []);
        });
    }, [initApp]);

    useEffect(() => {
        businessSectorsApiService.find({limit: -1}).then(res => {
            setBusinessSector(res.data.data || []);
        });
    }, [initApp]);

    const handleChangeVille = (event) => {
        event.persist()
        setSelectedVille(event.target.value)
        form.setFieldsValue({
            ville: event.target.value || null
        })
    };

    const handleChangeBusinessSector = (event) => {
        event.persist()
        setSelectedBusinessSector(event.target.value)
        form.setFieldsValue({
            business_sector_id: event.target.value || null
        })
    };

    useEffect(() => {
        getAdInfo()
    }, []);

    useEffect(() => {
        let formData = {...advertiser, ...{}};
        form.setFieldsValue(formData);
        form.setFieldsValue({
            first_name: formData.first_name,
            last_name: formData.last_name,
            marque_name: formData?.marque_name,
            ig_username: formData?.ig_username,
            contact_tel: formData?.contact_tel,
            ville: formData?.ville,
            gender: formData?.gender,
            business_sector_id: formData?.business_sector_id,
            age: formData?.ages,
            cible: formData?.cible,
            categories: formData?.categories

        });
    }, [advertiser]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        disableDrawerUpdate(false);
        setStateDrawer(open);
    };

    const onFinish = values => {
        const formProfile = values;
        formProfile.business_sector_id = selectedBusinessSector;
        formProfile.ville = selectedVille;
        formProfile.ages = age;
        formProfile.cible = selectedCible;
        formProfile.advertiser_id = advertiser_id;
        formProfile.profile_efile_id = ad_efile_id
        advertiserApiService.updateAdvertiserInfo(formProfile).then(res => {
            message.success('Account updated with success', 3);
            disableDrawerUpdate(false)
        }).catch(err => {
            const {data} = err.response;
            if (data && data.messages) {
                console.log(data)
                data.messages.forEach(msg => {
                    message.error(translateMsg(msg, intl));
                });
            }
        });
    };

    const handleChangeAge = (event) => {
        setAge(event.target.value);
        form.setFieldsValue({
            ages: event.target.value || null
        })
    };

    const handleChangeCategories = (selected_categories) => {
        setSelectedCategories(selected_categories);
    };

    const handleChangeCible = (selected_cible) => {
        setselectedCible(selected_cible);
        form.setFieldsValue({
            cible: selected_cible || null
        })
    };

    const handleCancel = () => {
        setPreviewVisible(false)
    };

    const handlePreview = (file) => {
        setPreviewPicture(file.url || file.thumbUrl)
        setPreviewVisible(true)
    };

    const handleUploadClick = event => {
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            return post(siteConfig.AppConfig.fileUploadUrl, formData, {
                headers: {
                    'Content-Type': `multipart/form-data;`,
                },
                timeout: 30000,
            }).then(res => {
                setAd_efile_id(res.data.data)
            })
        }
    };
    const adPicture = [{
        uid: -1,
        name: 'advertiser picture',
        status: 'done',
        url: siteConfig.AppConfig.baseStyleImageUrl + ad_efile_id,
    }]

    const handleChange = () => {
        ad_efile_id(null)
    }


    const FormProfile = (anchor) => {
        return (<div
                className={clsx(classes.list, {
                    [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                })}
                role="presentation"
            >
                <Card className="gx-card" title="Update Profile">
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="update"
                        onFinish={onFinish}
                        initialValues={{
                            prefix: '216',
                        }}
                        scrollToFirstError
                        autoComplete={"off"}
                    >
                        <Form.Item
                            name="username"
                            label="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your user name!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="first_name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First name!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label="Family Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Last name!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="contact_tel"
                            label="Phone Number"
                            rules={[{required: true, message: 'Please input your phone number!'}]}
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        {/*<Form.Item*/}
                        {/*    name="user_email"*/}
                        {/*    label="Email"*/}
                        {/*>*/}
                        {/*    <Input style={{width: '100%'}} disabled={true}/>*/}
                        {/*</Form.Item>*/}
                        <Form.Item
                            name="ig_username"
                            label="Instagram user name"
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <FormItem
                            name="ville"
                            label="Ville"
                            rules={[{required: true, message: 'Please select your ville!'}]}
                        >
                            <FormControl>
                                <Select
                                    multiple={true}
                                    labelId="demo-simple-select-label"
                                    value={selectedVille}
                                    onChange={handleChangeVille}
                                    defaultValue={selectedVille}
                                >
                                    {tawaCities.map(option => {
                                        return <MenuItem value={option.value}
                                                         key={option.value}>{option.label}</MenuItem>
                                    })}
                                </Select>

                            </FormControl>
                        </FormItem>
                        <Form.Item
                            name="marque_name"
                            label="Marque name"
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <FormItem
                            name="business_sector_id"
                            label="Business sector"
                            rules={[{required: true, message: 'Please select your Business sector!'}]}
                        >
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={selectedBusinessSector}
                                    onChange={handleChangeBusinessSector}
                                >
                                    {businessSector.map(option => {
                                        return <MenuItem value={option.business_sector_id}
                                                         key={option.business_sector_id}>{option.label}</MenuItem>
                                    })}
                                </Select>

                            </FormControl>
                        </FormItem>

                        <Form.Item
                            name="cible"
                            label="Cible"
                            rules={[{required: true, message: 'Please select your gender!'}]}
                        >
                            <Checkbox.Group onChange={handleChangeCible}>
                                <Checkbox className={"gx-ml-0 gx-mr-1 gx-mb-1"} key='H' value="H"> Homme</Checkbox>
                                <Checkbox className={"gx-ml-0 gx-mr-1 gx-mb-1"} key='F' value="F"> Femme</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>

                        <Form.Item
                            name="ages"
                            label="age"
                            rules={[{required: true, message: 'Please select your age!'}]}
                        >
                            <FormControl className={classes.formControl}>
                                <Select
                                    multiple={true}
                                    labelId="demo-simple-select-label"
                                    id="age"
                                    value={age}
                                    onChange={handleChangeAge}
                                    defaultValue={age}
                                >
                                    <MenuItem value={'18-24'}>18-24</MenuItem>
                                    <MenuItem value={'25-34'}>25-34</MenuItem>
                                    <MenuItem value={'35-44'}>35-44</MenuItem>
                                    <MenuItem value={'45-60'}>45-60</MenuItem>
                                </Select>
                            </FormControl>
                        </Form.Item>

                        <Form.Item
                            name="categories"
                            label="Categories"
                            rules={[{required: true, message: 'Please select your Categories!'}]}
                        >
                            <Checkbox.Group onChange={handleChangeCategories}>
                                {categories.map(option => {
                                    return <Checkbox className={"gx-ml-0 gx-mr-1 gx-mb-1"} key={option.value}
                                                     value={option.category_id}>{option.label}</Checkbox>
                                })}
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item
                            name="passwordhash"
                            label="Password"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your password!',
                                },
                            ]}
                            autoComplete={"new-password"}
                            hasFeedback
                        >
                            <Input.Password autoComplete={"new-password"}/>
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['passwordhash']}
                            hasFeedback
                            rules={[
                                {
                                    required: false,
                                    message: 'Please confirm your password!',
                                },
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if ((!value && !getFieldValue('passwordhash')) || getFieldValue('passwordhash') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password autoComplete={"new-password"}/>
                        </Form.Item>

                        <Form.Item
                            label={"Advertiser picture"}>
                            <div className={"group-file-upload"}>
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleUploadClick}
                                />
                                <Button icon={<UploadOutlined/>} onClick={() => {
                                    document.getElementById('contained-button-file').click()
                                }}>Click to upload</Button>
                            </div>
                        </Form.Item>
                        {ad_efile_id ?
                            <Card title="Advertiser picture" className="gx-card clearfix">
                                <Upload
                                    listType="picture-card"
                                    fileList={adPicture}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                >
                                </Upload>
                                <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                    <img alt="Talent picture" style={{width: '100%'}} src={previewPicture}/>
                                </Modal>
                            </Card> : ''
                        }
                        <Form.Item {...tailFormItemLayout} >
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                            <Button type="secondary" onClick={() => disableDrawerUpdate(false)}><IntlMessages
                                id="drawer.cancel"/></Button>
                        </Form.Item>
                    </Form>
                </Card>

            </div>
        );
    };

    return (
        <Drawer anchor={'right'} open={stateDrawer}
                onClose={toggleDrawer(false)}>
            {FormProfile('right')}
        </Drawer>
    )
};

export default UpdateProfileAdvertiser;
